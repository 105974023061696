import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SUPPLIER from "../api/services/SUPPLIER";
import { SearchContext, SocketContext } from "../App";
import Loader from "../components/Loader";
import Spinner from "../components/Spinner";
import Layout from "../layouts/Layout";
import { paths } from "../routes/paths";
import { isTokenExpired } from "../utils/isTokenExpired";
import LicenseModal from "../components/LicenseModal";
import { BASE_URL, IMAGE_BASE_URL } from "../config";
import Modal from "react-modal";
import downloadImage from "../assets/down3.gif";
import downloadIos from "../assets/appstore_btn.png";
import downloadAndroid from "../assets/googleplay_btn.png";
import styles from "../styles/_pages/supplierRequests.module.scss";
import { fieldNames, useLicenseSearch } from "../hooks/useLicenseSearch";
import axios from "axios";

function userTypeHeb(param) {
  switch (param) {
    case "GARAGE":
      return "מוסך";
    case "PRIVATE":
      return "פרטי";
    case "SUPPLIER":
      return "ספק";
    case "APPRAISER":
      return "שמאי";
    case "LEASING_COMPANY":
      return "חברת ליסינג";
    case "INSURANCE_COMAPNY":
      return "חברת ביטוח";
  }
}

const SupplierRequests = () => {
  const navigate = useNavigate();

  let token = localStorage.getItem("authToken");

  let userType = localStorage.getItem("user_type");

  // get socket
  const socket = useContext(SocketContext);

  // REDIRECT TO LOGIN IF NO TOKEN
  useEffect(() => {
    if (!token) {
      navigate(paths.login);
    }
  }, [token]);

  // IF THE USER IS SUPPLIER THEN NAVIGATE HIM FROM THIS SCREEN
  useEffect(() => {
    if (JSON.parse(userType) !== "SUPPLIER") {
      navigate(paths.main);
    }
  }, [userType]);

  const [requests, setRequests] = useState();

  const [requestsCopy, setRequestsCopy] = useState();

  const [request, setRequest] = useState();

  const [selectedRequests, setSelectedRequests] = useState([]);

  const [priceVal, setPriceVal] = useState("");

  const [comment, setComment] = useState("");

  const [searchVal, setSearchVal] = useState("");

  const [deleteSuccess, setDeleteSuccess] = useState(null);

  const [deleteLoading, setDeleteLoading] = useState(false);

  const [approveWithPriceLoading, setApprovePriceLoading] = useState(false);

  const [imagePopup, setImagePopup] = useState(null);

  const [licensePopup, setLicensePopup] = useState(null);

  const [responseSuccess, setResponseSuccess] = useState(null);

  const [priceAndComments, setPriceAndComments] = useState([]);

  const [priceErrorList, setPriceErrorList] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const [hasShownPopup, setHasShownPopup] = useState(false);

  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  // const [currentPage, setCurrentPage] = useState(1);

  // const [itemsPerPage, setItemsPerPage] = useState(10);

  const [archiveLoading, setArchiveLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const recordsPerPage = 20;
  const pageLimit = 20;

  const [lowerPageBound, setLowerPageBound] = useState(0);
  const [upperPageBound, setUpperPageBound] = useState(pageLimit);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const hasShownPopup = sessionStorage.getItem("hasShownPopup") === "true";

    if (isMobile && !hasShownPopup) {
      const timeoutId = setTimeout(() => {
        setIsOpen(true);
        sessionStorage.setItem("hasShownPopup", "true");
      }, 5000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, []);

  const fetchRequests = async () => {
    try {
      const res = await SUPPLIER.supplier_requests(
        currentPage,
        recordsPerPage,
        false
      );
      if (res.result.length > 0) {
        let filtered = res.result.filter((x) => !x.isArchivedBySupplier);
        // console.log(filtered, "====== REQUESTS ======");
        setRequestsCopy(filtered);
        setRequests(filtered);
        setTotalPages(res.totalPages);

        // const initialValues = Array(filtered.length).fill("");

        let newValues = [];
        filtered.forEach((i) => {
          newValues.push({
            price: "",
            comment: "",
            priceError: "",
            id: i._id,
          });
        });

        setPriceAndComments(newValues);
      } else {
        setRequests([]);
        setRequestsCopy([]);
      }
    } catch (error) {
      //console.log(error);
      setRequests([]);
      setRequestsCopy([]);
      isTokenExpired(error.response.data.status, navigate);
    }
  };

  // ============= FETCH REQUESTS =============
  useEffect(() => {
    setDeleteSuccess(null);
    setResponseSuccess(null);
    fetchRequests();
  }, [deleteSuccess, responseSuccess, currentPage]);

  // ============= PAGINATION CODE =============
  // Create an array from 1 to totalPages
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  // Pagination handlers
  const handlePaginationClick = (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleNextClick = () => {
    if (currentPage + 1 > upperPageBound) {
      setLowerPageBound(lowerPageBound + pageLimit);
      setUpperPageBound(upperPageBound + pageLimit);
    }
    if (currentPage + 1 <= totalPages) {
      setCurrentPage(currentPage + 1);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePrevClick = () => {
    if ((currentPage - 1) % pageLimit === 0) {
      setLowerPageBound(lowerPageBound - pageLimit);
      setUpperPageBound(upperPageBound - pageLimit);
    }
    if (currentPage - 1 >= 1) {
      setCurrentPage(currentPage - 1);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // ============= PAGINATION CODE END =============

  // ============= MOVE TO ARCHIVE =============
  const moveToArchive = async (subID, id, status) => {
    const payload = { id, status: true };
    const replyPayload = {
      subscriber: subID,
      request: id,
      status: "DELETE",
    };

    try {
      const res = await SUPPLIER.move_to_archive(payload);

      if (status !== "no") {
        const replyRes = await SUPPLIER.create_reply(replyPayload);
      }

      // toast.success(res.data.message);
      // navigate(paths.supplierArchive);
      // setResponseSuccess(true);
    } catch (error) {
      //console.log(error);
      toast.error(error.response.data.message);
    }
  };

  // ============= DELETE REQUEST =============
  const deleteRequest = async (subID, requestID) => {
    setDeleteLoading(true);

    // Immediately remove the request from UI
    const newRequests = requestsCopy.filter((i) => i._id !== requestID);
    setRequestsCopy(newRequests);

    try {
      const payload = {
        subscriber: subID,
        request: requestID,
        status: "DELETE",
      };

      const res = await SUPPLIER.create_reply(payload);
      // toast.success(res.data.message);
      // setDeleteSuccess(true);
      // moveToArchive(subID, requestID, "no");
    } catch (error) {
      // If error occurs, revert the state change
      setRequestsCopy(requestsCopy);
      toast.error(error.response.data.message);
    }
    setDeleteLoading(false);
  };

  // ============= MOVE ALL TO ARCHIVE =============
  const moveAllToArchive = async () => {
    let allRequestIds = requestsCopy.map((i) => i._id);

    let payload = {
      requestIds: allRequestIds,
    };

    setArchiveLoading(true);
    try {
      const res = await SUPPLIER.move_all_to_archive(payload);
      toast.success("כל הפריטים סומנו כלא במלאי");
      setResponseSuccess(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
      if (currentPage !== 1) {
        setCurrentPage(currentPage - 1);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setIsArchiveModalOpen(false);
    setArchiveLoading(false);
  };

  // ============= ACCEPT WITH PRICE =============
  const acceptWithPrice = async (idx, subID, requestID, price, comment) => {
    // Validate price
    if (!price || isNaN(price) || Number(price) < 1) {
      const newValues = [...priceAndComments];
      newValues.find((x) => x.id === requestID).priceError =
        "חובה להזין סכום גדול מ-0";
      setPriceAndComments(newValues);
      return; // Stop the function if validation fails
    }

    setApprovePriceLoading(true);

    // Immediately remove the request from UI
    const newRequests = requestsCopy.filter((i) => i._id !== requestID);
    setRequestsCopy(newRequests);

    try {
      const payload = {
        subscriber: subID,
        request: requestID,
        price,
        status: "ACCEPT_WITH_PRICE",
        comment,
      };

      const res = await SUPPLIER.create_reply(payload);
      // toast.success(res.data.message);
      moveToArchive(subID, requestID, "no");
      // setResponseSuccess(true);

      // remove price error
      // const newValues = [...priceErrorList];
      // newValues[idx] = "";
      // setPriceErrorList(newValues);
    } catch (error) {
      // If error occurs, revert the state change
      setRequestsCopy(requestsCopy);
      toast.error(error.response.data.message);
    }
    setApprovePriceLoading(false);
  };

  // ============= HANDLE SEARCH =============
  const handleSearch = (e) => {
    setSearchVal(e.target.value);
    let search = e.target.value;
    if (search.trim()) {
      if (requests) {
        if (requests.length > 0) {
          let filtered = requests.filter(
            (i) =>
              i.subscriberId?.name.includes(search) ||
              i.customPartName.includes(search) ||
              i.part?.name.includes(search) ||
              i.carLicenseNumber.includes(search)
          );
          setRequestsCopy(filtered);
        }
      }
    } else {
      if (requests) {
        if (requests.length > 0) {
          setRequestsCopy(requests);
        }
      }
    }
  };

  // ============= SOCKET LISTENER =============
  // useEffect(() => {
  //   socket.on("supplierPartRequest", (res) => {
  //     let request = res.request;
  //     request.part = request.customPartName
  //       ? { name: request.customPartName }
  //       : request.part;
  //     setRequestsCopy((prev) => [request, ...prev]);
  //   });

  //   return () => {
  //     socket.off("supplierPartRequest");
  //   };
  // });

  // Function to refresh the page
  const refreshPage = () => {
    window.location.reload();
  };

  // refresh the page every 20 minutes
  const refreshInterval = 20 * 60 * 1000;

  // Refresh the page every 20 minutes (20 minutes * 60 seconds * 1000 milliseconds)
  useEffect(() => {
    // Set up a timeout to refresh the page
    const timeoutId = setTimeout(refreshPage, refreshInterval);

    // Clear the timeout when the component unmounts to prevent memory leaks
    return () => clearTimeout(timeoutId);
  }, []);

  const { licenseAPIData, licenceNumber } = useContext(SearchContext);

  const [licenceNum, setLicenceNum] = licenceNumber;

  const [licenseData, setLicenseData] = licenseAPIData;

  const { searchForLicense, loading } = useLicenseSearch();

  const handleLicenseSearch = React.useCallback(
    async (text) => {
      try {
        const response = await searchForLicense(text);
        if (response.success) {
          // setLicenseData(response.data);
          setLicenseData((prev) => {
            return {
              ...response.data,
              shnat_yitzur: response.localData.yop,
            };
          });
        } else {
          setLicenseData({});
          toast.error(response.message);
        }
      } catch (e) {
        console.log("Error while searching license. Reason:", e);
      }
    },
    [searchForLicense]
  );

  const [dropCardOpen, setDropCardOpen] = useState(false);

  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }

  return (
    <Layout>
      <Modal
        style={{
          content: {
            height: "auto",
            width: "95%",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
        isOpen={isOpen}
        onRequestClose={toggleModal}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            padding: "1rem",
            cursor: "pointer",
            fontSize: "24px",
            fontWeight: "bold",
          }}
          onClick={toggleModal}
        >
          X
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <img src={downloadImage} />
          <p style={{ fontWeight: "bold", fontSize: "1.4em" }}>
            הורידו את האפליקציה שלנו
          </p>
          <p style={{ fontSize: "1.1em", marginBottom: "15px" }}>
            קבלו התראות על לקוחות שמחפשים חלקים בזמן אמת והגיבו להם ישירות
            מהאפליקציה
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5px",
            }}
          >
            <a href="https://apps.apple.com/il/app/get-part/id6443868231">
              <img
                style={{ maxWidth: "100%" }}
                src="/assets/images/AppStore.svg"
                alt="appstore"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.gettparts.app">
              <img
                style={{ maxWidth: "100%" }}
                src="/assets/images/GooglePlay.svg"
                alt="google_play"
              />
            </a>
          </div>
          {/* <button style={{
            backgroundColor: 'white',
            padding: '10px 20px',
            fontSize: '18px',
            fontFamily: `"Open Sans", sans-serif`,
            borderRadius:'5px',
            marginTop:'10px'

          }}
            onClick={toggleModal}>סגירה</button> */}
        </div>
      </Modal>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* CARD */}
          <div className={styles.card}>
            <h2>בקשות של לקוחות</h2>
            {/* <button onClick={toggleModal}>Show Popup</button> */}

            {/* SEARCH */}
            <div className={styles.search}>
              <div style={{ width: "225px" }}>
                <button
                  className={styles.hideonmobile}
                  style={{ cursor: "pointer", width: "100px" }}
                  onClick={() => window.location.reload(false)}
                >
                  רענון בקשות
                </button>
              </div>
              <input
                type="text"
                value={searchVal}
                onChange={handleSearch}
                placeholder="חיפוש"
              />
              {/* <button onClick={handleSearch}>חפש</button> */}
              {/* LICENSE NUMBER */}
              <div className={styles.licenseMainContainer}>
                {/* <h2>חיפוש פרטי כלי רכב</h2> */}
                <div
                  className={styles.licenseContainer}
                  style={{ marginBottom: "0" }}
                >
                  <form
                    className={styles.licenseRight}
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (licenceNum.length >= 5) {
                        handleLicenseSearch(licenceNum);
                      }
                    }}
                  >
                    <div className={styles.licenseNum}>
                      <div className={styles.licenseWrapper}>
                        <div className={styles.left}>
                          <input
                            type="text"
                            placeholder="חיפוש פרטי רכב"
                            value={licenceNum}
                            onChange={(e) => {
                              const numericValue = e.target.value.replace(
                                /\D/g,
                                ""
                              );
                              console.log(numericValue); // Log the value
                              setLicenceNum(numericValue);
                            }}
                          />
                        </div>
                        <div className={styles.right}>
                          <img src="/assets/icons/flag.svg" alt="flag" />
                          IL
                        </div>
                      </div>
                    </div>
                    {licenceNum.length >= 5 && (
                      <button type="submit">
                        {loading ? <Spinner /> : "חיפוש"}
                      </button>
                    )}
                  </form>

                  {isEmptyObject(licenseData) ? null : (
                    <button
                      disabled={!Object.keys(licenseData).length}
                      onClick={() => setDropCardOpen(!dropCardOpen)}
                    >
                      הצגת פרטי כלי הרכב
                    </button>
                  )}

                  {dropCardOpen && (
                    <div className={styles.dropCard}>
                      <span onClick={() => setDropCardOpen(false)}>
                        &times;
                      </span>
                      <ul>
                        {!!Object.keys(licenseData).length &&
                          Object.keys(fieldNames).map(
                            (i, idx) =>
                              licenseData[i] && (
                                <li key={idx}>
                                  <p>{fieldNames[i]}:</p>
                                  <p>{licenseData[i]}</p>
                                </li>
                              )
                          )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.refreshMob}>
              <button onClick={() => window.location.reload(false)}>
                רענון בקשות
              </button>
            </div>

            {/* loading */}
            {!requestsCopy && <Loader />}

            {/* ----------- TABLE DESKTOP ----------- */}
            {requestsCopy && (
              <div style={{ overflowX: "auto", width: "100%" }}>
                <table className={styles.mainTable}>
                  <thead>
                    <tr>
                      {/* checkbox - for later */}
                      {/* <th style={{ width: "70px" }}></th> */}

                      {/* Client Name */}
                      {/* <th>שם לקוח</th> for future */}

                      {/* search date  */}
                      <th>תאריך בקשה</th>

                      {/* User type  */}
                      <th>סוג לקוח</th>

                      {/* client city  */}
                      <th>עיר לקוח</th>

                      {/* Car license number */}
                      <th style={{ textAlign: "center" }}>מס' רכב</th>

                      {/* engine type */}
                      <th> מנוע</th>

                      {/* Part name */}
                      <th>חלק</th>

                      {/* Manufacturer */}
                      <th>יצרן ודגם</th>

                      {/* model */}
                      {/* <th>דגם</th> */}

                      {/* year */}
                      <th>שנה / דלת</th>

                      {/* gearbox */}
                      <th> הילוכים</th>

                      {/* horse power / engine capacity */}
                      <th>נפח </th>

                      {/* subscriber comment */}
                      <th>הערת לקוח</th>

                      {/* car model */}
                      {/* <th>דגם</th> for future */}

                      {/* year of production */}
                      {/* <th>שנת יצור</th> for future */}

                      {/* price */}
                      <th>הצע מחיר</th>

                      {/* comment */}
                      <th>מלא הערה</th>

                      {/* send */}
                      {/* <th></th> */}

                      {/* delete */}
                      <th></th>

                      {/* show results */}
                      {/* <th></th> */}

                      {/* move to archive */}
                      {/* <th style={{ display: "flex", justifyContent: "center" }}>
                        <button onClick={() => window.location.reload(false)}>
                          רענון בקשות
                        </button>
                      </th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {/* if no records */}
                    {requestsCopy.length === 0 ? (
                      <tr className={styles.noRecords}>
                        <td colspan={14}>
                          <h2>לא נמצאו רשומות</h2>
                        </td>
                      </tr>
                    ) : (
                      // if records
                      requestsCopy.map((i, idx) => {
                        return (
                          <TableRow
                            i={i}
                            idx={idx}
                            setImagePopup={setImagePopup}
                            acceptWithPrice={acceptWithPrice}
                            deleteRequest={deleteRequest}
                            request={request}
                            priceVal={priceVal}
                            setPriceVal={setPriceVal}
                            comment={comment}
                            setComment={setComment}
                            approveWithPriceLoading={approveWithPriceLoading}
                            deleteLoading={deleteLoading}
                            setLicensePopup={setLicensePopup}
                            requestsCopy={requestsCopy}
                            priceAndComments={priceAndComments}
                            setPriceAndComments={setPriceAndComments}
                            setRequestsCopy={setRequestsCopy} // <-- Add this line
                          />
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            )}

            <div className={styles.hideonmobile}>
              <button
                className={styles.allArchiveBtn}
                onClick={() => {
                  setIsArchiveModalOpen(true);
                }}
              >
                {archiveLoading ? <Spinner /> : "סמן הכל כלא במלאי"}
              </button>
            </div>
          </div>

          {/* ----------- CARDS MOBILE ----------- */}
          <div className={styles.mobile}>
            {requestsCopy ? (
              requestsCopy.length === 0 ? (
                <h2>לא נמצאו רשומות</h2>
              ) : (
                requestsCopy.map((i, idx) => (
                  <CardRow
                    i={i}
                    idx={idx}
                    setImagePopup={setImagePopup}
                    acceptWithPrice={acceptWithPrice}
                    deleteRequest={deleteRequest}
                    setLicensePopup={setLicensePopup}
                    requestsCopy={requestsCopy}
                    priceAndComments={priceAndComments}
                    setPriceAndComments={setPriceAndComments}
                    setRequestsCopy={setRequestsCopy} // <-- Add this line
                  />
                ))
              )
            ) : null}
            <button
              className={styles.allArchiveBtn}
              onClick={() => setIsArchiveModalOpen(true)}
            >
              סמן הכל כלא במלאי
            </button>
          </div>

          {/* ----------- PAGINATION ----------- */}
          <div className={styles.pagination}>
            <button disabled={currentPage === 1} onClick={handlePrevClick}>
              הקודם
            </button>
            <ul>
              {pages.slice(lowerPageBound, upperPageBound).map((page) => (
                <li
                  key={page}
                  style={page === currentPage ? { fontWeight: "bold" } : null}
                  className={`${page === currentPage ? styles.active : ""}`}
                  onClick={() => handlePaginationClick(page)}
                >
                  {page}
                </li>
              ))}
            </ul>
            <button
              disabled={currentPage === totalPages}
              onClick={handleNextClick}
            >
              הבא
            </button>
          </div>
        </div>

        {/* PART IMAGE MODAL */}
        {imagePopup && (
          <div className={styles.popupContainer}>
            <div
              style={{
                height: "100vh",
                width: "100%",
                position: "fixed",
                left: "0px",
                top: "0px",
                opacity: 0.45,
                background: "#000000 0% 0% no-repeat padding-box",
              }}
              onClick={() => setImagePopup(null)}
            ></div>
            <div className={styles.popup}>
              <span onClick={() => setImagePopup(null)}>&times;</span>
              <img src={imagePopup} alt="part" />
            </div>
          </div>
        )}

        {/*  LICENSE NUMBER DETAILS MODAL */}
        {licensePopup && (
          <LicenseModal
            setIsOpen={setLicensePopup}
            licenseData={licensePopup}
          />
        )}

        {/* SEND ALL TO ARCHIVE CONFIRMATION MODAL */}
        {isArchiveModalOpen && (
          <div className={styles.archiveModalMainContainer}>
            <Backdrop setIsOpen={setIsArchiveModalOpen} />
            <div className={styles.archiveModalContainer}>
              <span
                className={styles.close}
                onClick={() => setIsArchiveModalOpen(false)}
              >
                x
              </span>
              <div className={styles.content}>
                <p>האם אתה בטוח שתרצה לסמן את כל הדף כלא במלאי?</p>
                <div className={styles.btns}>
                  <button onClick={moveAllToArchive}>מחק בקשות</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SupplierRequests;

const style = {
  height: "100vh",
  width: "100%",
  position: "fixed",
  left: "0px",
  top: "0px",
  opacity: 0.45,
  background: "#000000 0% 0% no-repeat padding-box",
};

const Backdrop = ({ setIsOpen }) => (
  <div style={style} onClick={() => setIsOpen(false)}></div>
);

const TableRow = ({
  i,
  idx,
  setImagePopup,
  acceptWithPrice,
  deleteRequest,
  request,
  priceVal,
  setPriceVal,
  comment,
  setComment,
  approveWithPriceLoading,
  deleteLoading,
  setLicensePopup,
  requestsCopy,
  priceAndComments,
  setPriceAndComments,
  setRequestsCopy,
}) => {
  const { searchForLicense, licenseLoading } = useLicenseSearch();

  const [licenseDataToSend, setLicenseDataToSend] = useState(null);

  useEffect(() => {
    console.log(i,"iiiiiiii")
    if (i.carLicenseNumber) {
      const fetchData = async () => {
        try {
          const response = await searchForLicense(i.carLicenseNumber);
          console.log(response, "========== LICENSE DETAILS ===========");
          if (response.success) {
            setLicenseDataToSend({
              ...response.data,
              yop: response.localData.yop,
            });
          } else {
            setLicenseDataToSend({});
            toast.error(response.message);
          }
        } catch (e) {
          console.log("Error while searching license. Reason:", e);
        }
      };

      fetchData();
    }
  }, [requestsCopy, i.carLicenseNumber]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.post(
  //         `${BASE_URL}/customer/licenseDetails`,
  //         {
  //           licenseNumber: i.carLicenseNumber,
  //         }
  //       );
  //       console.log(response, "DB DATASET API RESPONSE");
  //       setLicenseDataToSend({
  //         ...response.data.result.data,
  //         yop: response.data.result.data.shnat_yitzur,
  //         nefah_manoa: i.hp,
  //       });
  //     } catch (e) {
  //       console.log("Error while searching license. Reason:", e);
  //     }
  //   };

  //   fetchData();
  // }, [requestsCopy]);

  // Load data in the background when the component mounts
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.post(
  //         `${BASE_URL}/customer/licenseDetails`,
  //         {
  //           licenseNumber: i.carLicenseNumber,
  //         }
  //       );
  //       setLicenseDataToSend({
  //         ...response.data.result.data,
  //         yop: response.data.result.data.shnat_yitzur,
  //       });
  //     } catch (e) {
  //       console.log("Error while searching license. Reason:", e);
  //     }
  //   };

  //   fetchData();
  // }, [i.carLicenseNumber]);
  return (
    <>
      <tr>
        {/* SEARCH DATE */}
        <td>
          {i.createdAt
            ? format(new Date(i.createdAt), "dd.MM.yyyy HH:mm")
            : "-"}
        </td>

        {/* SUBSCRIBER TYPE */}
        <td className={styles.part}>
          {userTypeHeb(i.subscriberId.subscriberType)}
        </td>

        {/* SUBSCRIBER CITY */}
        <td className={styles.part}>{i.subscriberId.city}</td>

        {/* CAR LICENSE NUMBER */}

        {/* CAR LICENSE NUMBER */}
        <td className={styles.license}>
          {i.carLicenseNumber ? (
            <div
              className={styles.licenseWrapper}
              onClick={() => {
                if (licenseDataToSend) {
                  setLicensePopup(licenseDataToSend);
                }
              }}
            >
              {i.carLicenseNumber}
              <img src="/assets/icons/info-icon.png" alt="info-icon" />
            </div>
          ) : (
            "-"
          )}
        </td>

        {/* ENGINE TYPE */}
        <td>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <p>
              {i.engineType === "GASOLINE"
                ? "בנזין"
                : i.engineType === "TURBO GASOLINE"
                ? "טורבו בנזין"
                : i.engineType === "DIESEL"
                ? "דיזל"
                : i.engineType === "TURBO DIESEL"
                ? "טורבו דיזל"
                : i.engineType === "HYBRID"
                ? "היברידי"
                : i.engineType === "ELECTRICAL POWER"
                ? "חשמלי"
                : i.engineType === "ELECTRICITY/GASOLINE"
                ? "חשמלי / בנזין"
                : ""}
            </p>
       
            {/* add degem_manoa here */}
            {i.carLicenseNumber &&
              licenseDataToSend &&
              Number(i.carLicenseNumber) ===
                Number(licenseDataToSend.mispar_rechev) && (
                <p>דגם: {licenseDataToSend?.degem_manoa}</p>
              )}
          </div>
        </td>
        {/* PART NAME AND IMAGE */}
        <td
          className={styles.part}
          // style={{ fontWeight: "bold" }}
        >
          {!i.customPartName ? (
            <div className={styles.part}>
              {i.images && i.images.length > 0
                ? i.images.map((i) => (
                    <img
                      src={`${IMAGE_BASE_URL}${i}`}
                      alt="part"
                      onClick={() => setImagePopup(`${IMAGE_BASE_URL}${i}`)}
                    />
                  ))
                : null}
              {!i.isVehicle && i.part && i.part.name
                ? i.part.name
                : "רכב למכירה לפירוק"}
            </div>
          ) : (
            <div className={styles.part}>
              {i.images && i.images.length > 0
                ? i.images.map((i) => (
                    <img
                      src={`${IMAGE_BASE_URL}${i}`}
                      alt="part"
                      onClick={() => setImagePopup(`${IMAGE_BASE_URL}${i}`)}
                    />
                  ))
                : null}
              {i.customPartName}
            </div>
          )}
        </td>

        {/* MANUFACTURER */}
        <td style={{ direction: "rtl" }}>
          {i.manufacturer}
          {" -"}
          {i.subModel && i.subModel}
          &nbsp;
          {i.model && i.model}
          &nbsp;
        </td>

        {/* MODEL */}
        {/* <td style={{ direction: "ltr" }}>
          {i.subModel && i.subModel}
          &nbsp;
          {i.model && i.model}
          &nbsp;
        </td> */}

        {/* year */}
        <td style={{ direction: "rtl" }}>
          <div className={styles.yearInfoContainer}>
            <div className={styles.yearAndDoors}>
              {i.yop && i.yop}
              {licenseDataToSend?.mispar_dlatot &&
              Number(licenseDataToSend.mispar_dlatot) > 0
                ? ` | ${licenseDataToSend.mispar_dlatot} ד׳`
                : " | 5 ד׳"}
            </div>
            {licenseDataToSend?.ramat_gimur && 
              <div className={styles.trimLevel}>
                רמת גימור: {licenseDataToSend?.ramat_gimur}
              </div>
            }
          </div>
        </td>

        {/* GEARBOX */}
        <td>
          {i.gearType === "AUTOMATIC"
            ? "אוטומטי"
            : i.gearType === "MANUAL"
            ? "ידני"
            : i.gearType === "TIPTONIC"
            ? "טיפטרוניק"
            : i.gearType === "ROBOTIC"
            ? "רובוטי"
            : ""}
            
        </td>

        {/* ENGINE CAPACITY / HORSE POWER */}
        <td>{i.hp}</td>

        {/* SUBSCRIBER COMMENT */}
        <td
          style={{
            textAlign: i.description ? "right" : "center",
          }}
        >
          {i.description ? i.description : "-"}
        </td>

        {/* CAR MODEL */}
        {/* <td>{i.model ? i.model : "-"}</td> - for future */}

        {/* YOP */}
        {/* <td>{i.yop ? i.yop : "-"}</td> - for future */}

        {/* PRICE */}
        <td>
          <input
            type="text"
            className={`${styles.inputField} ${
              priceAndComments.find((x) => x.id === i._id).priceError
                ? styles.fieldError
                : ""
            }`}
            value={priceAndComments.find((x) => x.id === i._id).price}
            onChange={(e) => {
              const value = e.target.value;

              // Allow only numbers
              if (/^\d*$/.test(value)) {
                const newValues = [...priceAndComments];

                // Check for empty or less than 1 value
                if (value === "" || Number(value) < 1) {
                  newValues.find((x) => x.id === i._id).priceError =
                    "חובה להזין סכום גדול מ-0";
                } else {
                  newValues.find((x) => x.id === i._id).priceError = "";
                }

                newValues.find((x) => x.id === i._id).price = value;
                setPriceAndComments(newValues);
              }
            }}
          />
          {priceAndComments.find((x) => x.id === i._id).priceError && (
            <small className={styles.fieldErrorText}>
              {priceAndComments.find((x) => x.id === i._id).priceError}
            </small>
          )}
        </td>

        {/* COMMENT */}
        <td>
          <input
            type="text"
            className={styles.inputField}
            value={priceAndComments.find((x) => x.id === i._id).comment}
            onChange={(e) => {
              const value = e.target.value;
              const newValues = [...priceAndComments];
              newValues.find((x) => x.id === i._id).comment = value;
              setPriceAndComments(newValues);
            }}
          />
        </td>

        {/* SEND BUTTON */}
        <td
          onClick={() => {
            const priceValue = priceAndComments.find(
              (x) => x.id === i._id
            ).price;

            if (!priceValue || isNaN(priceValue) || Number(priceValue) < 1) {
              const newValues = [...priceAndComments];
              newValues.find((x) => x.id === i._id).priceError =
                "חובה להזין סכום גדול מ-0";
              setPriceAndComments(newValues);
              return; // Stop the function if validation fails
            }

            const newRequests = requestsCopy.filter((req) => req._id !== i._id);
            setRequestsCopy(newRequests);
            acceptWithPrice(
              idx,
              i.subscriberId._id,
              i._id,
              priceValue,
              priceAndComments.find((x) => x.id === i._id).comment
            );
          }}
        >
          <img
            src="/assets/icons/check-2.png"
            alt="submit-icon"
            width={"40px"}
            height={"40px"}
          />
        </td>

        {/* DELETE */}
        <td style={{ cursor: "pointer" }}>
          <img
            src="/assets/icons/trash.svg"
            alt="delete"
            onClick={() => {
              const newRequests = requestsCopy.filter(
                (req) => req._id !== i._id
              );
              setRequestsCopy(newRequests);
              deleteRequest(i.subscriberId._id, i._id);
            }}
          />
        </td>

        {/* MOVE TO ARCHIVE */}
        {/* <td>
        <button
          onClick={() =>
            moveToArchive(i.subscriberId._id, i._id)
          }
        >
          העבר לארכיון
        </button>
      </td> */}

        {/* SHOW RESULTS */}
        {/* <td
      onClick={() => {
        setPriceVal("");
        setRequest(i._id);
      }}
    >
      <button>
        הצג תוצאות
      </button>
    </td> */}
      </tr>

      {i._id === request && (
        <tr className={styles.collapsedRow}>
          <td></td>
          {i.lastReply ? (
            <td colspan={10}>
              <div className={styles.already}>
                <p>כבר הגבת לבקשה זו</p>
              </div>
            </td>
          ) : (
            <td colspan={10}>
              <div className={styles.boxes}>
                <div className={styles.offer}>
                  <h6>שליחת הצעה עם תמחור</h6>
                  <input
                    type="number"
                    placeholder="הכנס סכום"
                    value={priceVal}
                    onChange={(e) => setPriceVal(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="הערה"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <button
                    onClick={() =>
                      acceptWithPrice(
                        i.subscriberId._id,
                        i._id,
                        priceVal,
                        comment
                      )
                    }
                  >
                    {approveWithPriceLoading ? <Spinner /> : "שלח תמחור"}
                  </button>
                </div>

                <div className={styles.reject}>
                  <button
                    onClick={() => deleteRequest(i.subscriberId._id, i._id)}
                  >
                    {deleteLoading ? <Spinner /> : "מחיקת בקשה"}
                  </button>
                </div>
              </div>
            </td>
          )}
        </tr>
      )}
    </>
  );
};

const CardRow = ({
  i,
  idx,
  setImagePopup,
  acceptWithPrice,
  deleteRequest,
  setLicensePopup,
  requestsCopy,
  priceAndComments,
  setPriceAndComments,
  setRequestsCopy,
}) => {
  const { searchForLicense, licenseLoading } = useLicenseSearch();

  const [licenseDataToSend, setLicenseDataToSend] = useState(null);

  useEffect(() => {
    if (i.carLicenseNumber) {
      const fetchData = async () => {
        try {
          const response = await searchForLicense(i.carLicenseNumber);
          if (response.success) {
            console.log("response.data", response.data);
            setLicenseDataToSend(response.data);
          } else {
            setLicenseDataToSend({});
            toast.error(response.message);
          }
        } catch (e) {
          console.log("Error while searching license. Reason:", e);
        }
      };

      fetchData();
    }
  }, [requestsCopy, i.carLicenseNumber]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.post(
  //         `${BASE_URL}/customer/licenseDetails`,
  //         {
  //           licenseNumber: i.carLicenseNumber,
  //         }
  //       );
  //       setLicenseDataToSend({
  //         ...response.data.result.data,
  //         yop: response.data.result.data.shnat_yitzur,
  //       });
  //     } catch (e) {
  //       console.log("Error while searching license. Reason:", e);
  //     }
  //   };

  //   fetchData();
  // }, [requestsCopy]);

  return (
    <div className={styles.itemCard}>
      <div className={styles.right}>
        {/* part */}
        <h2
          className={styles.partName}
          style={{
            fontSize: "20px",
            textAlign: "center",
            borderBottom: "1px solid #80808054",
            paddingBottom: "10px",
          }}
        >
          {!i.customPartName
            ? !i.isVehicle && i.part && i.part.name
              ? i.part.name
              : "רכב שלם"
            : i.customPartName}
        </h2>

        {/* Manufacturer */}
        <div className={styles.item}>
          <h3>יצרן:</h3>
          <p>
            <span>{i.manufacturer}</span>
          </p>
        </div>

        {/* model */}
        <div className={styles.item}>
          <h3>דגם:</h3>
          <p>
            <span>{i.model && i.model}</span>
            <span>{i.subModel && i.subModel}</span>
          </p>
        </div>

        {/* year */}
        <div className={styles.item}>
          {/* <h3>שנת ייצור:</h3> */}
          <h3>שנה / דלת:</h3>
          <p>
            <span>
              {" "}
              {i.yop && i.yop}
              {licenseDataToSend?.mispar_dlatot
                ? ` | ${licenseDataToSend?.mispar_dlatot} ד׳`
                : ""}
            </span>
          </p>
        </div>

        {/* Car license number */}
        <div className={styles.item} style={{ alignItems: "center" }}>
          <h3>מס' רכב:</h3>
          {i.carLicenseNumber &&
          licenseDataToSend &&
          Number(i.carLicenseNumber) ===
            Number(licenseDataToSend.mispar_rechev) ? (
            <p
              onClick={() => {
                if (i.carLicenseNumber) {
                  setLicensePopup(licenseDataToSend);
                }
              }}
            >
              {i.carLicenseNumber}
            </p>
          ) : (
            "-"
          )}
          <img
            style={{ width: "20px", height: "20px" }}
            src="/assets/icons/info-icon.png"
            alt="info-icon"
          />
        </div>

        {/* search date  */}
        <div className={styles.item}>
          <h3>תאריך בקשה:</h3>
          <p>
            {i.createdAt
              ? format(new Date(i.createdAt), "dd.MM.yyyy HH:mm")
              : "-"}
          </p>
        </div>

        {/* gearbox */}
        <div className={styles.item}>
          <h3>תיבת הילוכים:</h3>
          <p>
            {" "}
            {i.gearType === "AUTOMATIC"
              ? "אוטומטי"
              : i.gearType === "MANUAL"
              ? "ידני"
              : i.gearType === "TIPTONIC"
              ? "טיפטרוניק"
              : i.gearType === "ROBOTIC"
              ? "רובוטי"
              : ""}
          </p>
        </div>

        {/* engine type */}
        <div className={styles.item}>
          <h3>סוג מנוע:</h3>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <p>
              {i.engineType === "GASOLINE"
                ? "בנזין"
                : i.engineType === "TURBO GASOLINE"
                ? "טורבו בנזין"
                : i.engineType === "DIESEL"
                ? "דיזל"
                : i.engineType === "TURBO DIESEL"
                ? "טורבו דיזל"
                : i.engineType === "HYBRID"
                ? "היברידי"
                : i.engineType === "ELECTRICAL POWER"
                ? "חשמלי"
                : i.engineType === "ELECTRICITY/GASOLINE"
                ? "חשמלי / בנזין"
                : ""}
            </p>
            <p>דגם: {licenseDataToSend?.degem_manoa}</p>
          </div>
        </div>

        {/* horse power / engine capacity */}
        <div className={styles.item}>
          <h3>נפח מנוע:</h3>
          <p>{i.hp}</p>
        </div>

        {/* subscriber comment */}
        {i.description ? (
          <div className={styles.item}>
            <h3>הערת לקוח:</h3>
            <p>{i.description ? i.description : "-"}</p>
          </div>
        ) : (
          <></>
        )}
        {/* SUBSCRIBER TYPE */}
        <div className={styles.item}>
          <h3>סוג לקוח:</h3>
          {userTypeHeb(i.subscriberId.subscriberType)}
        </div>
        {/* SUBSCRIBER CITY */}
        <div className={styles.item}>
          <h3>עיר לקוח:</h3>
          {i.subscriberId.city}
        </div>
        {/* part image */}
        {i.images && i.images.length > 0 ? (
          <div className={`${styles.item} ${styles.imgItem}`}>
            <h3>תמונה:</h3>

            {i.images && i.images.length > 0
              ? i.images.map((i) => (
                  <img
                    src={`${IMAGE_BASE_URL}${i}`}
                    alt="part"
                    onClick={() => setImagePopup(`${IMAGE_BASE_URL}${i}`)}
                  />
                ))
              : null}
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className={styles.left}>
        <div className={styles.box}>
          <h3>הצעה</h3>
          {/* price */}
          <input
            type="text"
            placeholder="מחיר*"
            className={`${styles.inputField} ${
              priceAndComments.find((x) => x.id === i._id).priceError
                ? styles.fieldError
                : ""
            }`}
            value={priceAndComments.find((x) => x.id === i._id).price}
            onChange={(e) => {
              const value = e.target.value;

              // Allow only numbers
              if (/^\d*$/.test(value)) {
                const newValues = [...priceAndComments];

                // Check for empty or less than 1 value
                if (value === "" || Number(value) < 1) {
                  newValues.find((x) => x.id === i._id).priceError =
                    "חובה להזין סכום גדול מ-0";
                } else {
                  newValues.find((x) => x.id === i._id).priceError = "";
                }

                newValues.find((x) => x.id === i._id).price = value;
                setPriceAndComments(newValues);
              }
            }}
          />
          {priceAndComments.find((x) => x.id === i._id).priceError && (
            <small className={styles.fieldErrorText}>
              {priceAndComments.find((x) => x.id === i._id).priceError}
            </small>
          )}
          {/* comment */}
          <input
            type="text"
            placeholder="הערה"
            value={priceAndComments.find((x) => x.id === i._id).comment}
            onChange={(e) => {
              const value = e.target.value;
              const newValues = [...priceAndComments];
              newValues.find((x) => x.id === i._id).comment = value;
              setPriceAndComments(newValues);
            }}
          />
          <button
            onClick={() => {
              const priceValue = priceAndComments.find(
                (x) => x.id === i._id
              ).price;

              if (!priceValue || isNaN(priceValue) || Number(priceValue) < 1) {
                const newValues = [...priceAndComments];
                newValues.find((x) => x.id === i._id).priceError =
                  "חובה להזין סכום גדול מ-0";
                setPriceAndComments(newValues);
                return; // Stop the function if validation fails
              }

              const newRequests = requestsCopy.filter(
                (req) => req._id !== i._id
              );
              setRequestsCopy(newRequests);
              acceptWithPrice(
                idx,
                i.subscriberId._id,
                i._id,
                priceValue,
                priceAndComments.find((x) => x.id === i._id).comment
              );
            }}
          >
            שליחת בקשה
          </button>
        </div>

        <button
          className={styles.rejectBtn}
          onClick={() => deleteRequest(i.subscriberId._id, i._id)}
        >
          מחיקת בקשה
        </button>
        {/* <button
        className={styles.archiveBtn}
        onClick={() => moveToArchive(i.subscriberId._id, i._id)}
      >
        העבר לארכיון
      </button> */}
      </div>
    </div>
  );
};
