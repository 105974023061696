import styles from "../styles/_components/confirmModal.module.scss";

const style = {
  height: "100vh",
  width: "100%",
  position: "fixed",
  left: "0px",
  top: "0px",
  opacity: 0.45,
  background: "#000000 0% 0% no-repeat padding-box",
};

const Backdrop = ({ setIsOpen }) => (
  <div style={style} onClick={() => setIsOpen(false)}></div>
);

const ConfirmModal = ({ setIsOpen, heading, text, yesBtn, noBtn, onYes, note }) => {
  return (
    <div className={styles.mainContainer}>
      <Backdrop setIsOpen={setIsOpen} />
      <div className={styles.container}>
        <span className={styles.close} onClick={() => setIsOpen(false)}>
          x
        </span>
        <div className={styles.content}>
          <h2 className={styles.heading1}>{heading}</h2>
          <p className={styles.text}>{text}</p>
          <div className={styles.btns}>
            <button onClick={onYes}>{yesBtn}</button>
            <button onClick={() => setIsOpen(false)}>{noBtn}</button>
          </div>
          {note && <p className={styles.note}>{note}</p>}
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
