import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SearchContext } from "../../App";
import { paths } from "../../routes/paths";
import MANUFACTURERS from "../../api/services/MANUFACTURERS";
import Spinner from "../../components/Spinner";
import styles from "../../styles/_features/main/findCard.module.scss";
import downloadImage from "../../assets/down3.gif";
import downloadIos from "../../assets/appstore_btn.png";
import downloadAndroid from "../../assets/googleplay_btn.png";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { fieldNames, useLicenseSearch } from "../../hooks/useLicenseSearch";

const FindCard = ({ setIsOpen, dynamicContent }) => {
  let token = localStorage.getItem("authToken");
  token = JSON.parse(token);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const handleFocus = (e) => {
    if (e.target.value === "שנת ייצור") {
      setYop("");
    }
  };
  const {
    vehicleVal,
    manufacturerVal,
    modelVal,
    subModelVal,
    yopVal,
    gearTypeVal,
    engineTypeVal,
    hpVal,
    licenceNumber,
    disableAllField,
    manuList,
    modelsList,
    collapse,
    licenseAPIData,
  } = useContext(SearchContext);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const [vehicle, setVehicle] = vehicleVal;
  const [manufacturer, setManufacturer] = manufacturerVal;
  const [model, setModel] = modelVal;
  const [subModel, setSubModel] = subModelVal;
  const [yop, setYop] = yopVal;
  const [gearType, setGearType] = gearTypeVal;
  const [engineType, setEngineType] = engineTypeVal;
  const [hp, setHp] = hpVal;
  const [licenceNum, setLicenceNum] = licenceNumber;
  const [disableAll, setDisableAll] = disableAllField;
  const [manufacturers, setManufacturers] = manuList;
  const [models, setModels] = modelsList;
  const [collapseSections, setCollapseSections] = collapse;
  const [licenseData, setLicenseData] = licenseAPIData;

  const [subModelError, setSubModelError] = useState(null);
  const [yopError, setYopError] = useState(null);
  const [gearTypeError, setGearTypeError] = useState(null);
  const [engineTypeError, setEngineTypeError] = useState(null);
  const [hpError, setHpError] = useState(null);

  const [hasShownPopup, setHasShownPopup] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const hasShownPopup = sessionStorage.getItem("hasShownPopup") === "true";

    if (isMobile && !hasShownPopup) {
      const timeoutId = setTimeout(() => {
        setIsModalOpen(true);
        sessionStorage.setItem("hasShownPopup", "true");
      }, 5000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, []);

  // call manufacturers API
  const getManufacturers = async () => {
    try {
      const res = await MANUFACTURERS.get(vehicle);

      if (res.result.length === 0) {
        setManufacturers([]);
      } else {
        const sortedManufacturers = res.result.manufacturers.sort((a, b) => {
          // Compare manufacturers' names alphabetically
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        setManufacturers(sortedManufacturers);
        setModels(sortedManufacturers[0].models);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  // fetch manufacturers list
  useEffect(() => {
    getManufacturers();
  }, []);

  const onSubmit = (data) => {
    //console.log(data, "Data");

    // handle click
    // const handleClick = () => {
    //console.log("handleClick");
    // setCollapseSections(true);
    navigate(paths.createRequest);
    // };
    // handleClick();
    // //console.log(data);
  };

  // ===========================================================
  // =========== EXAMPLE LICENSE NO: 75348102, 6618355 =========
  // ===========================================================

  const [noResultsText, setNoResultsText] = useState(false);

  const { searchForLicense, loading } = useLicenseSearch();

  const handleLicenseSearch = React.useCallback(
    async (text) => {
      try {
        const response = await searchForLicense(text);
        console.log(response.success, "RESPONSE //////////");
        if (response.success) {
          // SET ALL LICENSE DATA
          setLicenseData(response.data);

          // CHECK IF MANUFACTURER EXISTS IN MANUFACTURERS ARRAY
          let doesExist = (manufacturers || []).find(
            (i) => i.name === response.localData.manufacturer
          );

          // IF IT EXISTS
          if (doesExist) {
            setManufacturer(response.localData.manufacturer);
            setModels(() => {
              if ((doesExist.models || []).includes(response.localData.model)) {
                return doesExist.models;
              } else {
                return [...(doesExist.models || []), response.localData.model];
              }
            });
            setModel(response.localData.model);
          }

          // IF IT DOESN'T EXISTS
          else {
            setManufacturers((prev) => [
              ...(prev || []),
              {
                id: response.localData.manufacturer,
                name: response.localData.manufacturer,
              },
            ]);
            setManufacturer(response.localData.manufacturer);
            setModels([response.localData.model]);
            setModel(response.localData.model);
          }

          // SET OTHER FIELDS
          setVehicle(response.localData.vehicle);

          if (response.localData.yop) {
            setYop(response.localData.yop);
            setLicenseData((prev) => {
              return {
                ...prev,
                shnat_yitzur: response.localData.yop,
              };
            });
          } else {
            setYopError(true);
          }
          if (response.localData.hp) {
            setHp(response.localData.hp);
          } else {
            setHpError(true);
          }
          if (response.localData.gearType) {
            setGearType(response.localData.gearType);
          } else {
            setGearTypeError(true);
          }
          if (response.localData.engineType) {
            setEngineType(response.localData.engineType);
          } else {
            setEngineTypeError(true);
          }
        } else {
          setLicenseData({});
          // toast.error(response.message);
          setNoResultsText(true);
        }
        console.log("FINAL RESP", response);
      } catch (e) {
        console.log("Error while searching license. Reason:", e);
      }
    },
    [manufacturers, searchForLicense]
  );

  const [dropCardOpen, setDropCardOpen] = useState(false);

  return (
    <>
      <Modal
        style={{
          content: {
            height: "auto",
            width: "95%",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
          },
        }}
        isOpen={isModalOpen}
        onRequestClose={toggleModal}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            padding: "1rem",
            cursor: "pointer",
            fontSize: "24px",
            fontWeight: "bold",
          }}
          onClick={() => setIsModalOpen(false)}
        >
          X
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            zIndex: 9999,
          }}
        >
          <img src={downloadImage} />
          <p style={{ fontWeight: "bold", fontSize: "1.4em" }}>
            הורידו את האפליקציה שלנו
          </p>
          <p style={{ fontSize: "1.1em", marginBottom: "15px" }}>
            חפשו חלקים וקבלו מחירים והתראות מספקים בזמן אמת ישירות לאפליקציה
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5px",
            }}
          >
            <a href="https://apps.apple.com/il/app/get-part/id6443868231">
              <img
                style={{ maxWidth: "100%" }}
                src="/assets/images/AppStore.svg"
                alt="appstore"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.gettparts.app">
              <img
                style={{ maxWidth: "100%" }}
                src="/assets/images/GooglePlay.svg"
                alt="google_play"
              />
            </a>
          </div>
          {/* <button style={{
            backgroundColor: 'white',
            padding: '10px 20px',
            fontSize: '18px',
            fontFamily: `"Open Sans", sans-serif`,
            borderRadius:'5px',
            marginTop:'10px'

          }}
            onClick={toggleModal}>סגירה</button> */}
        </div>
      </Modal>
      <div className={styles.container}>
        <h2>{dynamicContent.searchCardTitle}</h2>
        <p>{dynamicContent.searchCardDescription}</p>
        <h4>הזינו מספר רכב או מלאו פרטים</h4>

        {/* license number */}
        <div className={styles.licenseContainer}>
          <form
            className={styles.licenseRight}
            onSubmit={(e) => {
              e.preventDefault();
              if (licenceNum.length >= 5) {
                handleLicenseSearch(licenceNum);
              }
            }}
          >
            <div className={styles.licenseNum}>
              <div className={styles.wrapper}>
                <div className={styles.left}>
                  <input
                    type="text"
                    placeholder="מספר הרכב שלך"
                    value={licenceNum}
                    onChange={(e) => {
                      setNoResultsText(false);
                      const numericValue = e.target.value.replace(/\D/g, "");
                      console.log(numericValue); // Log the value
                      setLicenceNum(numericValue);
                    }}
                  />
                </div>
                <div className={styles.right}>
                  <img src="/assets/icons/flag.svg" alt="flag" />
                  IL
                </div>
              </div>
            </div>
            {licenceNum.length >= 5 && (
              <button type="submit">{loading ? <Spinner /> : "חיפוש"}</button>
            )}
          </form>

          {licenseData && (
            <button
              disabled={!Object.keys(licenseData).length}
              onClick={() => setDropCardOpen(true)}
            >
              פרטי כלי הרכב
            </button>
          )}

          {dropCardOpen && (
            <div className={styles.dropCard}>
              <span onClick={() => setDropCardOpen(false)}>&times;</span>
              <ul>
                {!!Object.keys(licenseData).length &&
                  Object.keys(fieldNames).map(
                    (i, idx) =>
                      licenseData[i] && (
                        <li key={idx}>
                          <p>{fieldNames[i]}:</p>
                          <p>{licenseData[i]}</p>
                        </li>
                      )
                  )}
              </ul>
            </div>
          )}
        </div>

        {noResultsText && (
          <p
            style={{
              marginBottom: "20px",
              color: "red",
              fontWeight: "bold",
            }}
          >
            לא מצאנו תוצאות עבור מספר הרישוי שהזנת יש להזין את פרטי הרכב באופן
            ידני בתיבה למטה, תודה.
          </p>
        )}

        {/* vehicles */}
        <div className={styles.tabs}>
          <button
            className={`${
              vehicle === "CAR" && !disableAll ? styles.active : ""
            } ${styles.tab}
            ${disableAll ? styles.disable : ""}
            `}
            onClick={() => setVehicle("CAR")}
            disabled={disableAll}
          >
            <img src="/assets/icons/car.svg" alt="car" />
            רכב
          </button>
          <button
            className={`${
              vehicle === "TRUCK" && !disableAll ? styles.active : ""
            } ${styles.tab} ${disableAll ? styles.disable : ""}`}
            onClick={() => setVehicle("TRUCK")}
            disabled={disableAll}
          >
            <img src="/assets/icons/truck.svg" alt="truck" />
            משאית
          </button>
          <button
            className={`${
              vehicle === "BIKE" && !disableAll ? styles.active : ""
            } ${styles.tab} ${disableAll ? styles.disable : ""}`}
            onClick={() => setVehicle("BIKE")}
            disabled={disableAll}
          >
            <img src="/assets/icons/bike.svg" alt="bike" />
            אופנוע
          </button>
        </div>

        <form
          className={styles.form}
          // onSubmit={handleSubmit(onSubmit)}
          onSubmit={() => navigate(paths.createRequest)}
        >
          {/* manufacturer */}
          <select
            {...register("manufacturer", { required: true })}
            name="manufacturer"
            value={manufacturer}
            style={{
              border: errors.manufacturer
                ? "2px solid red"
                : manufacturer === "לא צוין"
                ? "2px solid red"
                : "1px solid #95a5b2",
            }}
            onChange={(e) => {
              setManufacturer(e.target.value);
              setModels(() => {
                let item = manufacturers.find((i) => i.name === e.target.value);
                if (item) {
                  return item.models;
                } else {
                  return null;
                }
              });
            }}
          >
            <option value="" selected disabled>
              בחר יצרן
            </option>
            {manufacturers &&
              manufacturers.map((i) => (
                <option key={i.id} value={i.name}>
                  {i.name}
                </option>
              ))}
          </select>

          {/* model */}
          <select
            {...register("model")}
            name="model"
            value={model}
            style={{
              border:
                errors && errors.model ? "2px solid red" : "1px solid #95a5b2",
            }}
            onChange={(e) => setModel(e.target.value)}
          >
            <option value="" selected disabled>
              בחר דגם
            </option>
            {models &&
              models.map((i) => (
                <option value={i} key={i}>
                  {i}
                </option>
              ))}
          </select>

          {/* sub model */}
          <input
            {...register("subModel", { required: false })}
            type="text"
            value={subModel}
            onChange={(e) => {
              setSubModelError(null);
              setSubModel(e.target.value);
            }}
            placeholder="תת דגם"
            style={{
              border: subModelError ? "2px solid red" : "1px solid #95a5b2",
            }}
          />

          {/* yop */}
          <input
            {...register("yop", { required: true })}
            type="number"
            value={yop}
            onChange={(e) => {
              setYopError(null);
              setYop(e.target.value);
            }}
            placeholder="שנת ייצור"
            // style={{
            //   border:
            //     (errors && errors.yop) || (!yop && manufacturer) || yopError
            //       ? "2px solid red"
            //       : "1px solid #95a5b2",
            // }}
            style={{
              border: yopError ? "2px solid red" : "1px solid #95a5b2",
            }}
          />

          {/* gear type */}
          {vehicle !== "BIKE" && (
            <select
              {...register("gearType")}
              name="gearType"
              value={gearType}
              onChange={(e) => {
                setGearTypeError(null);
                setGearType(e.target.value);
              }}
              style={{
                border:
                  gearTypeError || gearType === "תיבת הילוכים"
                    ? "2px solid red"
                    : "1px solid #95a5b2",
              }}
            >
              <option value="" selected disabled>
                תיבת הילוכים
              </option>
              <option value="AUTOMATIC">אוטומטי</option>
              <option value="MANUAL">ידני</option>
              <option value="TIPTONIC">טיפטרוניק</option>
              <option value="ROBOTIC">רובוטי</option>
            </select>
          )}

          {/* engine type */}
          <select
            {...register("engineType")}
            name="engineType"
            value={engineType}
            onChange={(e) => {
              setEngineTypeError(null);
              setEngineType(e.target.value);
            }}
            style={{
              border: engineTypeError ? "2px solid red" : "1px solid #95a5b2",
            }}
          >
            <option value="" selected disabled>
              סוג מנוע
            </option>
            <option value="GASOLINE">בנזין</option>
            <option value="TURBO GASOLINE">טורבו בנזין</option>
            <option value="DIESEL">דיזל</option>
            <option value="TURBO DIESEL">טורבו דיזל</option>
            <option value="HYBRID">היברידי</option>
            <option value="ELECTRICAL POWER">חשמלי</option>
            <option value="ELECTRICITY/GASOLINE">חשמלי / בנזין</option>
            <option value="בנזין">בנזין</option>
            <option value="GAPAM">גפ"מ</option>
          </select>

          {/* hp */}
          <input
            {...register("hp", { required: true })}
            type="text"
            value={hp}
            onChange={(e) => {
              setHpError(null);
              setHp(e.target.value);
            }}
            placeholder="נפח מנוע"
            style={{
              border: hpError ? "2px solid red" : "1px solid #95a5b2",
            }}
          />

          {vehicle === "BIKE" ? (
            <>
              <button
                type="submit"
                disabled={!manufacturer || !model || !yop || !hp}
              >
                <span>{">"}</span>
                חפש לי חלק
              </button>
            </>
          ) : (
            <>
              <button
                type="submit"
                disabled={
                  !manufacturer ||
                  manufacturer === "לא צוין" ||
                  !model ||
                  model === "בחר דגם" ||
                  yop == "שנת ייצור" ||
                  !yop ||
                  !hp ||
                  !engineType ||
                  engineType === "" ||
                  !gearType
                }
              >
                <span>{">"}</span>
                חפש לי חלק
              </button>

              {(!manufacturer ||
                !model ||
                model === "בחר דגם" ||
                yop == "שנת ייצור" ||
                !yop ||
                !hp ||
                !engineType ||
                engineType === "" ||
                !gearType) && (
                // ||
                // gearType === "תיבת הילוכים"
                <>
                  <p
                    style={{
                      color: "red",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    על מנת לחפש יש למלא את כל השדות
                  </p>
                </>
              )}
            </>
          )}

          <p className={styles.demolishText} onClick={() => setIsOpen(true)}>
            אני רוצה לפרסם רכב שלם לפירוק / חלקים
          </p>
        </form>
      </div>
    </>
  );
};

export default FindCard;
