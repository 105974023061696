import React, { useState, useEffect, useContext, useRef } from "react";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import SUBSCRIBER from "../api/services/SUBSCRIBER";
import MANUFACTURERS from "../api/services/MANUFACTURERS";
import Layout from "../layouts/Layout";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import { SocketContext } from "../App";
import Loader from "../components/Loader";
import { isTokenExpired } from "../utils/isTokenExpired";
import LicenseModal from "../components/LicenseModal";
import { toast } from "react-toastify";
import { IMAGE_BASE_URL } from "../config";
import styles from "../styles/_pages/subscriberRequests.module.scss";
import { useLicenseSearch } from "../hooks/useLicenseSearch";
import ReactToPrint from "react-to-print";
import ConfirmModal from "../components/ConfirmModal";

const SubscriberRequests = () => {
  let token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const componentRefs = useRef({}); // to keep track of each row's ref

  // get socket
  const socket = useContext(SocketContext);

  let userType = localStorage.getItem("user_type");

  // REDIRECT TO LOGIN IF NO TOKEN
  useEffect(() => {
    if (!token) {
      navigate(paths.login);
    }
  }, [token]);

  // IF THE USER IS SUPPLIER THEN NAVIGATE HIM FROM THIS SCREEN
  // useEffect(() => {
  //   if (JSON.parse(userType) !== "SUBSCRIBER") {
  //     navigate(paths.supplierHistory);
  //   }
  // }, [userType]);

  const [startDate, setStartDate] = useState();

  const [endDate, setEndDate] = useState();

  const [manufacturers, setManufacturers] = useState();

  const [manufacturer, setManufacturer] = useState(null);

  const [requests, setRequests] = useState();

  const [requestsCopy, setRequestsCopy] = useState();

  const [request, setRequest] = useState([]);

  const [selectedRequests, setSelectedRequests] = useState([]);

  const [imagePopup, setImagePopup] = useState(null);

  const [licensePopup, setLicensePopup] = useState(null);

  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const [licenseDataMap, setLicenseDataMap] = useState({});
  const { searchForLicense } = useLicenseSearch();

  // FETCH REQUESTS
  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const res = await SUBSCRIBER.subscriber_requests();
        if (res.result.length > 0) {
          let filtered = res.result.filter((x) => !x.isArchivedBySubscriber);
          setRequests(filtered);
          setRequestsCopy(filtered);
        } else {
          setRequests([]);
          setRequestsCopy([]);
        }
      } catch (error) {
        //console.log(error);
        setRequests([]);
        setRequestsCopy([]);
        isTokenExpired(error.response.data.status, navigate);
      }
    };

    fetchRequests();
  }, []);

  // FETCH MANUFACTURERS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await MANUFACTURERS.get();

        if (res.result.length === 0) {
          setManufacturers([]);
        } else {
          setManufacturers(res.result.manufacturers);
        }
      } catch (error) {
        //console.log(error);
      }
    };

    fetchData();
  }, []);

  // FILTER BY MANUFACTURER
  useEffect(() => {
    if (manufacturer) {
      if (requests) {
        if (requests.length > 0) {
          let filtered = requests.filter(
            (i) => i.manufacturer === manufacturer
          );
          setRequestsCopy(filtered);
        }
      }
    }
  }, [manufacturer, requests]);

  // Replace the license search effect
  useEffect(() => {
    const fetchLicenseData = async (licenseNumber) => {
      try {
        const response = await searchForLicense(licenseNumber);
        if (response.success) {
          setLicenseDataMap((prev) => ({
            ...prev,
            [licenseNumber]: response.data,
          }));
        } else {
          setLicenseDataMap((prev) => ({
            ...prev,
            [licenseNumber]: {},
          }));
          toast.error(response.message);
        }
      } catch (e) {
        console.log("Error while searching license. Reason:", e);
      }
    };

    if (requestsCopy?.length > 0) {
      requestsCopy.forEach((request) => {
        if (
          request.carLicenseNumber &&
          request.carLicenseNumber !== "000000" &&
          !licenseDataMap[request.carLicenseNumber]
        ) {
          fetchLicenseData(request.carLicenseNumber);
        }
      });
    }
  }, [requestsCopy]);

  const onDateChange = (dates) => {
    const [start, end] = dates;

    //console.log(start, "start");
    //console.log(end, "end");
    setStartDate(start);
    setEndDate(end);

    let filtered;

    // check if requests exists
    if (requests?.length > 0) {
      // if start date exists and end date doesnt exists
      if (start && !end) {
        filtered = requests.filter(
          (i) =>
            format(new Date(i.createdAt), "dd,MM,yyyy") ===
            format(new Date(start), "dd,MM,yyyy")
        );
        setRequestsCopy(filtered);
      }

      // if start date and end date exists
      if (start && end) {
        filtered = requests.filter(
          (i) =>
            format(new Date(i.createdAt), "dd,MM,yyyy") >
              format(new Date(start), "dd,MM,yyyy") &&
            format(new Date(i.createdAt), "dd,MM,yyyy") <
              format(new Date(end), "dd,MM,yyyy")
        );
        setRequestsCopy(filtered);
      }
    }
  };

  const [checkedCardDetails, setCheckedCardDetails] = useState([]);

  // HANDLE CHECKBOX
  const handleCheckBox = (id) => {
    if (selectedRequests.includes(id)) {
      setSelectedRequests(selectedRequests.filter((j) => j !== id));
      setCheckedCardDetails(
        checkedCardDetails.filter((card) => card._id !== id)
      );
    } else {
      setSelectedRequests((prev) => [...prev, id]);
      const cardDetails = requestsCopy.find((i) => i._id === id);
      if (cardDetails) {
        setCheckedCardDetails((prev) => [...prev, cardDetails]);
      }
    }
  };

  function handleCheckboxChange(e, id) {
    handleCheckBox(id); // Use the same handler for both views
  }

  // MOVE TO ARCHIVE
  const moveToArchive = async (id) => {
    const payload = { id, status: true };

    try {
      const res = await SUBSCRIBER.move_to_archive(payload);
      toast.success(res.data.message);
      if (JSON.parse(userType) === "SUBSCRIBER") {
        navigate(paths.subscriberArchive);
      }
      setIsConfirmModal(false);
    } catch (error) {
      //console.log(error);
      toast.error(error.response.data.message);
    }
  };

  // SOCKET LISTENER
  useEffect(() => {
    socket.on("subscriberRequestReply", (res) => {
      // //console.log(res, 'SOCKET RESPONSEEEEE')
      window.location.reload();
      setRequestsCopy((prev) => {
        const newState = prev.map((i) => {
          if (i._id === res.reply.request) {
            return { ...i, supplierReplies: [...i.supplierReplies, res.reply] };
          }

          return i;
        });

        return newState;
      });
    });

    return () => {
      socket.off("subscriberRequestReply");
    };
  });

  const handlePrint = (id) => {
    // Hide all rows and dropdowns
    document
      .querySelectorAll(".row-content, .dropdown-content")
      .forEach((el) => {
        el.style.display = "none";
      });

    // Check for existence of specific row and dropdown before trying to modify style
    const specificRow = document.querySelector(`#row-${id}`);
    const specificDropdown = document.querySelector(`#dropdown-${id}`);

    if (specificRow) {
      specificRow.style.display = "table-row";
    }
    if (specificDropdown) {
      specificDropdown.style.display = "table-row";
    }

    // Print the document
    window.print();

    // Show all rows and dropdowns again after printing
    document
      .querySelectorAll(".row-content, .dropdown-content")
      .forEach((el) => {
        el.style.display = "table-row";
      });
  };

  // PRINT THE CHECKED ROWS' REPLIERS
  const printRequests = () => {
    const duplicates = [];

    // get only rows that are checked
    for (const obj1 of requestsCopy) {
      for (const obj2 of selectedRequests) {
        if (obj1._id === obj2) {
          duplicates.push(obj1);
          break;
        }
      }
    }

    // remove if the supplier replies array is empty
    for (let i = duplicates.length - 1; i >= 0; i--) {
      if (duplicates[i].supplierReplies.length === 0) {
        duplicates.splice(i, 1);
      }
    }

    // hide all rows
    document.querySelectorAll(".row-content").forEach((el) => {
      if (el) {
        el.style.display = "none";
      }
    });

    document.querySelectorAll(".dropdown-content").forEach((el) => {
      if (el) {
        el.style.display = "none";
      }
    });

    // only show checked rows and their dropdown content
    duplicates.forEach((i) => {
      let rowElement = document.querySelector(`#row-${i._id}`);
      let dropdownElement = document.querySelector(`#dropdown-${i._id}`);

      if (rowElement) {
        rowElement.style.display = "table-row";
      }

      if (dropdownElement) {
        dropdownElement.style.display = "table-row";
      }
    });

    window.print();

    // show everything back again after print
    document.querySelectorAll(".row-content").forEach((el) => {
      if (el) {
        el.style.display = "table-row";
      }
    });
    document.querySelectorAll(".dropdown-content").forEach((el) => {
      if (el) {
        el.style.display = "none";
      }
    });
  };

  function handleCheckboxChange(e, id) {
    const isChecked = e.target.checked;
    const cardDetails = requestsCopy.find((i) => i._id === id);

    if (isChecked) {
      setCheckedCardDetails((prevDetails) => [...prevDetails, cardDetails]);
    } else {
      setCheckedCardDetails((prevDetails) =>
        prevDetails.filter((card) => card._id !== id)
      );
    }
  }

  function convertCardDetailsToHtml(cardDetails) {
    // Convert the card details to HTML string
    return cardDetails
      .map(
        (detail) => `
      <div>
        <h3>חלק:</h3>
        <p>${
          detail.customPartName ||
          (detail.part ? detail.part.name : "רכב למכירה / לפירוק")
        }</p>
  
        <!-- Other card details here -->
  
        <!-- Loop through supplierReplies to add dropdown content -->
        ${detail.supplierReplies
          .map(
            (reply) => `
          <div>
            <!-- SUPPLIER NAME -->
            <h3>שם הספק:</h3>
            <p>${reply.supplier[0].name}</p>
  
            <!-- PRICE -->
            <h3>מחיר:</h3>
            <p>${reply.price ? "₪" + reply.price : "לא קיים במלאי"}</p>
  
            <!-- COMMENT -->
            <h3>הערה מספק:</h3>
            <p>${reply.comment ? reply.comment : "-"}</p>
  
            <!-- ADDRESS -->
            <h3>כתובת הספק:</h3>
            <p>${reply.supplier[0].address}, ${reply.supplier[0].city}</p>
  
            <!-- PHONE -->
            <h3>יצירת קשר:</h3>
            <p>${reply.supplier[0].phone}</p>
          </div>
        `
          )
          .join("")}
      </div>
    `
      )
      .join("");
  }

  function printRequestsMobile() {
    const printWindow = window.open("", "", "width=600,height=600");

    const htmlString = convertCardDetailsToHtml(checkedCardDetails);

    printWindow.document.open();
    printWindow.document.write("<html><head><title>Print</title></head><body>");
    printWindow.document.write(htmlString);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  }

  const handlePrintRow = (data, id1) => {
    const printableContent = document.getElementById(id1);

    if (printableContent) {
      const printWindow = window.open("", "", "width=1200,height=600");
      printWindow.document.open();
      printWindow.document.write(
        "<html><head><title>הדפסת תשובות ספקים</title>"
      );
      printWindow.document.write("<style>");

      printWindow.document.write(`
        body { font-size: 12px; direction: rtl; }
      tr {
        background: #f6f6f6 0% 0% no-repeat padding-box;
        height: 46px;
      }
      td {
        text-align: right;
        font: normal normal normal 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }


      td:nth-child(1) {
        padding: 0px 10px 0px 0px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      td:nth-child(10) {
        padding: 0px 0px 0px 20px;
        text-align: left;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      button {
        width: 90px;
        height: 25px;
        background: #3762ec 0% 0% no-repeat padding-box;
        border-radius: 5px;
        border: none;
        font: normal normal normal 13px/17px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
        cursor: pointer;
      }

      td:nth-child(9) {
        padding: 0px 0px 0px 20px;
        text-align: left;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      td:nth-child(9) div {
        display: flex;
        justify-content: flex-end;
        font: normal normal bold 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: var(--secondary-color);
        cursor: pointer;
      }

      td:nth-child(9) div img {
        margin-left: 5px;
      }
    `);

      printWindow.document.write("</style>");
      printWindow.document.write("</head><body>");

      printWindow.document.write(`
      <div style="text-align:center">
      <img
        src="https://get-part.co.il/assets/icons/logo_8.svg"
        alt="logo"
        width="218px"
        height="46px"
        style="margin-bottom:20px"
      />
      <h1>
      הודפס מהאתר get-part.co.il
      </h1>
      </div>
      `);

      printWindow.document.write(`
      <table>
        <tbody>
      `);

      printWindow.document.write("<tr>");
      printWindow.document.write(`
      <td>${data.manufacturer}</td>
      <td>${data.carLicenseNumber}</td>
      <td>
        ${
          !data.customPartName
            ? `<div>
            ${
              !data.isVehicle && data.part && data.part.name
                ? data.part.name
                : "רכב שלם"
            }
          </div>`
            : `<div>
            ${data.customPartName}
          </div>`
        }
      </td>
      <td>
        ${format(new Date(data.createdAt), "dd.MM.yyyy - HH:mm")}
      </td>
      <td>${data.model ? data.model : "-"}</td>
      <td>${data.yop ? data.yop : "-"}</td>
      `);

      printWindow.document.write("</tr>");

      printWindow.document.write("<tr>");
      printWindow.document.write(printableContent.innerHTML);
      printWindow.document.write("</tr>");
      printWindow.document.write("</tbody>");
      printWindow.document.write("</table>");
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
      // printWindow.close();
    } else {
      console.warn("Printable content not found.");
    }
  };

  return (
    <Layout>
      <style>
        {`
        @media print {
          @page {
            size: landscape;
          margin-right: -10%;
          padding: 0;

           }
           .footer, .header {
            display: none !important;
          }
           @page wide {
            size: a4 landscape;
          }
          
          table {
            transform: scale(0.9);
            transform-origin: top;
            page-break-after: always;
            margin: 0;
            padding: 0;
          }
        }
      `}
      </style>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* CARD */}
          <div className={styles.card}>
            <div>
              <h2>החיפושים שלי</h2>

              {/* BUTTON ----------- */}
              {/* <button className={styles.btn1}>
              <div>מספר הרכב שלך</div>
            </button> */}

              {/* SEARCH FIELDS ----------- */}
              <div className={styles.search}>
                {/* SEARCH BY MANUFACTURER */}
                <div className={styles.field}>
                  <label>חיפוש יצרן</label>
                  <select
                    name="manufacturer"
                    value={manufacturer}
                    onChange={(e) => {
                      setManufacturer(e.target.value);
                    }}
                  >
                    <option value="" disabled selected>
                      בחר יצרן
                    </option>
                    {manufacturers &&
                      manufacturers.map((i) => (
                        <option key={i.id} value={i.name}>
                          {i.name}
                        </option>
                      ))}
                  </select>
                </div>

                {/* SEARCH BY DATE - RANGE */}
                <div className={styles.field}>
                  <label>חיפוש מתאריך</label>
                  <div className={styles.datePicker}>
                    <img src="/assets/icons/calendar.svg" alt="calendar" />
                    <DatePicker
                      selected={startDate}
                      onChange={onDateChange}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      placeholderText={format(new Date(), "dd.MM.yyyy")}
                      // inline
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* TABLE ----------- */}
            <div className={styles.tableWrapper}>
              {!requestsCopy ? (
                <Loader />
              ) : (
                <div
                  style={{ overflowX: "auto", width: "100%" }}
                  id="printablediv"
                >
                  <table className={styles.mainTable}>
                    <thead>
                      <tr>
                        <th style={{ width: "70px" }}></th>
                        {/* <th>מספר בקשה</th> */}
                        <th>יצרן</th>
                        <th>מס' רכב</th>
                        <th>חלק</th>
                        <th>תאריך בקשה</th>
                        {/* <th>דגם</th> */}
                        <th>שנת יצור</th>
                        <th>תשובה מספקים</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* if no records */}
                      {requestsCopy.length === 0 ? (
                        <tr className={styles.noRecords}>
                          <td colspan={8}>
                            <h2>לא נמצאו רשומות</h2>
                          </td>
                        </tr>
                      ) : (
                        requestsCopy.map((i, idx) => {
                          // Check if the ref for this ID already exists, if not, create one
                          if (!componentRefs.current[i._id]) {
                            componentRefs.current[i._id] = React.createRef();
                          }
                          return (
                            <React.Fragment key={i._id}>
                              <tr
                                id={`row-${i._id}`}
                                ref={componentRefs.current[i._id]}
                                className="row-content"
                                onClick={() => {
                                  if (request.includes(i._id)) {
                                    setRequest(
                                      request.filter((x) => i._id !== x)
                                    );
                                  } else if (
                                    i.supplierReplies &&
                                    i.supplierReplies.length > 0
                                  ) {
                                    setRequest((prev) => [...prev, i._id]);
                                  }
                                }}
                                style={{
                                  cursor:
                                    i.supplierReplies &&
                                    i.supplierReplies.length > 0
                                      ? "pointer"
                                      : "default",
                                }}
                              >
                                <td>
                                  <input
                                    type="checkbox"
                                    id={`checkbox-${i._id}`}
                                    checked={selectedRequests.includes(i._id)}
                                    onChange={(e) =>
                                      handleCheckboxChange(e, i._id)
                                    }
                                  />
                                </td>
                                {/* req id */}
                                {/* <td>{i._id}</td> */}
                                {/* manufacturer */}
                                <td>
                                  {i.manufacturer}
                                  {i.model || i.yop
                                    ? ` - ${[i.model, i.yop]
                                        .filter(Boolean)
                                        .join(", ")}`
                                    : ""}
                                </td>
                                {/* vehicle no */}
                                <LicenseRowDesktop
                                  i={i}
                                  setLicensePopup={setLicensePopup}
                                />
                                {/* part name */}
                                <td className={styles.part}>
                                  {!i.customPartName ? (
                                    <div className={styles.part}>
                                      {i.images && i.images.length > 0
                                        ? i.images.map((i) => (
                                            <img
                                              src={`${IMAGE_BASE_URL}${i}`}
                                              alt="part"
                                              onClick={() =>
                                                setImagePopup(
                                                  `${IMAGE_BASE_URL}${i}`
                                                )
                                              }
                                            />
                                          ))
                                        : null}
                                      {!i.isVehicle && i.part && i.part.name
                                        ? i.part.name
                                        : "רכב שלם"}
                                    </div>
                                  ) : (
                                    <div className={styles.part}>
                                      {i.images && i.images.length > 0
                                        ? i.images.map((i) => (
                                            <img
                                              src={`${IMAGE_BASE_URL}${i}`}
                                              alt="part"
                                              onClick={() =>
                                                setImagePopup(
                                                  `${IMAGE_BASE_URL}${i}`
                                                )
                                              }
                                            />
                                          ))
                                        : null}
                                      {i.customPartName}
                                    </div>
                                  )}
                                </td>
                                {/* date */}
                                <td>
                                  {format(
                                    new Date(i.createdAt),
                                    "dd.MM.yyyy - HH:mm"
                                  )}
                                </td>
                                {/* model */}
                                {/* <td>{i.model ? i.model : "-"}</td> */}
                                {/* yop */}
                                {/* <td>{i.yop ? i.yop : "-"}</td> */}
                                {/* replies */}
                                <td>
                                  {i.supplierReplies &&
                                  i.supplierReplies.length > 0
                                    ? `ענו ${i.supplierReplies.length} ספקים`
                                    : "-"}
                                </td>

                                {/* open dropdown */}
                                <td onClick={() => setRequest(i._id)}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    {i.supplierReplies &&
                                      i.supplierReplies.length > 0 && (
                                        <div style={{ textAlign: "right" }}>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <span
                                              style={{
                                                color: "blue",
                                                fontWeight: 600,
                                              }}
                                            >
                                              הצגת תוצאות (
                                              {i.supplierReplies &&
                                              i.supplierReplies.length > 0
                                                ? `${i.supplierReplies.length}`
                                                : "-"}
                                              )
                                            </span>

                                            <span
                                              style={{
                                                color: "green",
                                              }}
                                            >
                                              {i.minSupplierPrice > 0 && (
                                                <span
                                                  style={{
                                                    color: "white",
                                                    backgroundColor: "green",
                                                    padding: "1px",
                                                  }}
                                                >
                                                  המחיר הטוב ביותר{" "}
                                                  {new Intl.NumberFormat(
                                                    "he-IL",
                                                    {
                                                      style: "currency",
                                                      currency: "ILS",
                                                      minimumFractionDigits: 0,
                                                      maximumFractionDigits: 0,
                                                    }
                                                  ).format(i.minSupplierPrice)}
                                                </span>
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </td>
                                {request.includes(i._id) && (
                                  <td>
                                    <button
                                      style={{
                                        width: "90px",
                                        height: "25px",
                                        background:
                                          "rgb(55, 98, 236) no-repeat padding-box padding-box",
                                        borderRadius: "5px",
                                        border: "none",
                                        fontFamily: '"Open Sans", sans-serif',
                                        fontSize: "13px",
                                        lineHeight: "17px",
                                        letterSpacing: "0px",
                                        color: "rgb(255, 255, 255)",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handlePrintRow(i, `dropdown-${i._id}`)
                                      }
                                    >
                                      הדפסת בקשה
                                    </button>
                                  </td>
                                )}
                                {/* move to archive */}
                                <td>
                                  <button
                                    style={{
                                      width: "90px",
                                      height: "25px",
                                      background:
                                        "#3762ec 0% 0% no-repeat padding-box",
                                      borderRadius: "5px",
                                      border: "none",
                                      fontFamily: '"Open Sans", sans-serif',
                                      fontSize: "13px",
                                      lineHeight: "17px",
                                      letterSpacing: "0px",
                                      color: "#ffffff",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setIsConfirmModal(i._id)}
                                  >
                                    העבר לארכיון
                                  </button>
                                </td>

                                <td></td>
                              </tr>
                              {request.includes(i._id) && (
                                <>
                                  <tr
                                    id={`dropdown-${i._id}`}
                                    className={`${styles.collapsedRow} dropdown-content`}
                                  >
                                    <td colspan={11}>
                                      <table className={styles.collapsedTable}>
                                        <thead>
                                          {/* <th style={{ width: "70px" }}></th> */}
                                          <th>תאריך תגובה</th>
                                          <th>שם הספק</th>
                                          <th>מחיר</th>
                                          <th>הערה מספק</th>
                                          <th>כתובת הספק</th>
                                          <th>יצירת קשר</th>
                                        </thead>
                                        <tbody>
                                          {i.supplierReplies &&
                                            i.supplierReplies.map((j) => {
                                              return (
                                                <tr>
                                                  <td
                                                    style={{
                                                      border:
                                                        "1px solid  #00000073",
                                                    }}
                                                  >
                                                    <p>
                                                      {format(
                                                        new Date(j.createdAt),
                                                        "dd.MM.yyyy - HH:mm:ss"
                                                      )}
                                                    </p>
                                                  </td>
                                                  <td
                                                    style={{
                                                      border:
                                                        "1px solid  #00000073",
                                                    }}
                                                  >
                                                    {j.supplier[0].name}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border:
                                                        "1px solid  #00000073",
                                                    }}
                                                  >
                                                    {j.price &&
                                                      new Intl.NumberFormat(
                                                        "he-IL",
                                                        {
                                                          style: "currency",
                                                          currency: "ILS",
                                                          minimumFractionDigits: 0,
                                                          maximumFractionDigits: 0,
                                                        }
                                                      ).format(j.price)}
                                                    <p>
                                                      {j.price === ""
                                                        ? "לא קיים במלאי"
                                                        : ""}
                                                    </p>
                                                  </td>

                                                  <td
                                                    style={{
                                                      border:
                                                        "1px solid  #00000073",
                                                    }}
                                                  >
                                                    {j.comment && j.comment}
                                                    {j.status === "DELETE" && (
                                                      <span
                                                        style={{
                                                          color: "#cc1919",
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {" "}
                                                        לא קיים במלאי
                                                      </span>
                                                    )}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border:
                                                        "1px solid  #00000073",
                                                    }}
                                                  >
                                                    {j.supplier[0].address},{" "}
                                                    {j.supplier[0].city}
                                                  </td>
                                                  <td
                                                    style={{
                                                      border:
                                                        "1px solid  #00000073",
                                                    }}
                                                  >
                                                    <div
                                                      className={styles.contact}
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems:
                                                          "flex-start",
                                                        gap: "5px",
                                                      }}
                                                    >
                                                      <a
                                                        href={`tel:+972${j.supplier[0].phone}`}
                                                      >
                                                        {j.supplier[0].phone}
                                                      </a>
                                                      <a
                                                        href={`tel:+972${j.supplier[0].additionalPhone}`}
                                                      >
                                                        {
                                                          j.supplier[0]
                                                            .additionalPhone
                                                        }
                                                      </a>
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </React.Fragment>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            {/* BUTTONS */}
            {selectedRequests.length > 0 && (
              <div className={styles.btnsContainer}>
                <button onClick={printRequests}>הדפס</button>
              </div>
            )}
          </div>

          {/* CARD MOBILE */}
          <div className={styles.cardsWrapper}>
            {/* CARDS */}
            {!requestsCopy ? (
              <Loader />
            ) : (
              <div className={styles.cards}>
                {requestsCopy.length === 0 ? (
                  <h2>לא נמצאו רשומות</h2>
                ) : (
                  requestsCopy.map((i, idx) => (
                    <>
                      <div
                        id={`card-${i._id}`}
                        className={`${styles.reqCard} ${
                          request.includes(i._id) ? styles.collapsed : ""
                        }`}
                      >
                        <div className={styles.cardActions}>
                          <input
                            type="checkbox"
                            id={`checkbox-${i._id}`}
                            checked={selectedRequests.includes(i._id)}
                            onChange={(e) => handleCheckboxChange(e, i._id)}
                          />
                          <button
                            className={styles.archiveBtn}
                            onClick={() => moveToArchive(i._id)}
                          >
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M21 8v13H3V8M1 4h22v4H1V4zm12 12h-2v-6h2v6z"
                                stroke="currentColor"
                                strokeWidth="2"
                              />
                            </svg>
                            העבר לארכיון
                          </button>
                        </div>

                        <div className={styles.cardContent}>
                          <div className={styles.mainInfo}>
                            <div className={styles.partInfo}>
                              {i.images && i.images.length > 0 && (
                                <div className={styles.imageGallery}>
                                  {i.images.map((x) => (
                                    <img
                                      src={`${IMAGE_BASE_URL}${x}`}
                                      alt="part"
                                      onClick={() =>
                                        setImagePopup(`${IMAGE_BASE_URL}${x}`)
                                      }
                                    />
                                  ))}
                                </div>
                              )}
                              <div className={styles.details}>
                                <div className={styles.row}>
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    />
                                  </svg>
                                  <span className={styles.label}>חלק:</span>
                                  <span className={styles.value}>
                                    {!i.customPartName
                                      ? !i.isVehicle && i.part && i.part.name
                                        ? i.part.name
                                        : "רכב למכירה / לפירוק"
                                      : i.customPartName}
                                  </span>
                                </div>

                                <div className={styles.row}>
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M19 16v3m0-8V8m-7 11h7.8c.88 0 1.6-.72 1.6-1.6v-1.8c0-.85-.67-1.55-1.51-1.6L17 14H7l-2.9.03c-.84.05-1.51.75-1.51 1.6v1.77c0 .88.72 1.6 1.6 1.6H12m7-3.5h-2.8c-.88 0-1.6-.72-1.6-1.6v-.7c0-.88.72-1.6 1.6-1.6h2.8c.88 0 1.6.72 1.6 1.6v.7c0 .88-.72 1.6-1.6 1.6zM7 14l1.5-4.5h7L17 14"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    />
                                  </svg>
                                  <span className={styles.label}>רכב:</span>
                                  <span className={styles.value}>
                                    {i.manufacturer}
                                    {i.model || i.yop
                                      ? ` - ${[i.model, i.yop]
                                          .filter(Boolean)
                                          .join(", ")}`
                                      : ""}
                                  </span>
                                </div>

                                <div className={styles.row}>
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M4 13h16M4 13l4-4m-4 4l4 4"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    />
                                  </svg>
                                  <span
                                    style={{
                                      color:
                                        i.carLicenseNumber &&
                                        i.carLicenseNumber !== "000000"
                                          ? "#3762ec"
                                          : "#666",
                                      cursor:
                                        i.carLicenseNumber &&
                                        i.carLicenseNumber !== "000000"
                                          ? "pointer"
                                          : "default",
                                      fontWeight:
                                        i.carLicenseNumber &&
                                        i.carLicenseNumber !== "000000"
                                          ? "500"
                                          : "normal",
                                    }}
                                    onClick={() => {
                                      if (
                                        i.carLicenseNumber &&
                                        i.carLicenseNumber !== "000000" &&
                                        licenseDataMap[i.carLicenseNumber]
                                      ) {
                                        setLicensePopup(
                                          licenseDataMap[i.carLicenseNumber]
                                        );
                                      }
                                    }}
                                  >
                                    {i.carLicenseNumber &&
                                    i.carLicenseNumber !== "000000"
                                      ? i.carLicenseNumber
                                      : "לא הוזן מספר רכב"}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className={styles.metaInfo}>
                              <div className={styles.row}>
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                  />
                                </svg>
                                <span>
                                  {format(
                                    new Date(i.createdAt),
                                    "dd.MM.yyyy - HH:mm"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className={styles.cardFooter}>
                            <div className={styles.supplierInfo}>
                              <div className={styles.infoRow}>
                                <div className={styles.supplierCount}>
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2m22 0v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    />
                                  </svg>
                                  {i.supplierReplies &&
                                  i.supplierReplies.length > 0
                                    ? `ענו ${i.supplierReplies.length} ספקים`
                                    : "מחפש ספקים.."}
                                </div>
                                {i.minSupplierPrice > 0 && (
                                  <div className={styles.bestPrice}>
                                    <svg
                                      width="14"
                                      height="14"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4M12 6a6 6 0 000 12 6 6 0 000-12z"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                      />
                                    </svg>
                                    המחיר הטוב ביותר{" "}
                                    {new Intl.NumberFormat("he-IL", {
                                      style: "currency",
                                      currency: "ILS",
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                    }).format(i.minSupplierPrice)}
                                  </div>
                                )}
                              </div>
                              {i.supplierReplies.length > 0 && (
                                <button
                                  className={styles.showResultsBtn}
                                  onClick={() => {
                                    if (request.includes(i._id)) {
                                      setRequest(
                                        request.filter((x) => i._id !== x)
                                      );
                                    } else if (
                                      i.supplierReplies &&
                                      i.supplierReplies.length > 0
                                    ) {
                                      setRequest((prev) => [...prev, i._id]);
                                    }
                                  }}
                                >
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M19 9l-7 7-7-7"
                                      stroke="white"
                                      strokeWidth="2"
                                    />
                                  </svg>
                                  הצגת תוצאות
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* CARD COLLAPSE / SUPPLIER RESPONSE */}
                      {request.includes(i._id) && (
                        <div
                          id={`dropdown-${i._id}`}
                          className={styles.collapsedCards}
                          style={{
                            display: request.includes(i._id) ? "block" : "none",
                          }}
                        >
                          {i.supplierReplies &&
                            i.supplierReplies.map((j) => (
                              <div className={styles.collapsedCard}>
                                {/* SUPPLIER NAME */}
                                <div className={styles.item}>
                                  <h3>שם הספק:</h3>
                                  <p>{j.supplier[0].name}</p>
                                </div>

                                {/* CAR LICENSE NUMBER */}
                                {/* <div className={styles.item}>
                                  <h3>מספר רישוי:</h3>
                                  <p>
                                    {i.carLicenseNumber
                                      ? i.carLicenseNumber
                                      : "-"}
                                  </p>
                                </div> */}

                                {/* PRICE */}
                                <div className={styles.item}>
                                  <h3>מחיר:</h3>
                                  <p>
                                    {j.price
                                      ? new Intl.NumberFormat("he-IL", {
                                          style: "currency",
                                          currency: "ILS",
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 0,
                                        }).format(j.price)
                                      : "לא קיים במלאי"}
                                  </p>
                                </div>

                                {/* COMMENT */}
                                <div className={styles.item}>
                                  <h3>הערה מספק:</h3>
                                  <p>
                                    {j.comment ? j.comment : ""}
                                    {j.price ? "₪" + j.price : "לא קיים במלאי"}
                                  </p>
                                </div>

                                {/* reply time */}
                                <div className={styles.item}>
                                  <h3>תאריך ושעת תגובה</h3>
                                  <p>
                                    {format(
                                      new Date(j.createdAt),
                                      "dd.MM.yyyy - HH:mm:ss"
                                    )}
                                  </p>
                                </div>

                                {/* ADDRESS */}
                                <div className={styles.item}>
                                  <h3>כתובת הספק:</h3>
                                  <p>
                                    {j.supplier[0].address},{j.supplier[0].city}
                                  </p>
                                </div>

                                {/* PHONE */}
                                <div className={styles.item}>
                                  <h3>יצירת קשר:</h3>
                                  <p
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "3px",
                                    }}
                                  >
                                    <a href={`tel:+972${j.supplier[0].phone}`}>
                                      {j.supplier[0].phone}
                                    </a>
                                    {(() => {
                                      let partName =
                                        i.customPartName ||
                                        (!i.isVehicle &&
                                          i.part &&
                                          i.part.name) ||
                                        "רכב למכירה / לפירוק";
                                      let message = `שלום, אני שולח הודעה בקשר לחלק ${partName} שחיפשתי בגט-פארט`;
                                      return (
                                        <a
                                          style={{ color: "green" }}
                                          href={`https://wa.me/972${
                                            j.supplier[0].phone
                                          }?text=${encodeURIComponent(
                                            message
                                          )}`}
                                        >
                                          שליחת הודעת Whatsapp
                                        </a>
                                      );
                                    })()}
                                  </p>
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                    </>
                  ))
                )}
              </div>
            )}
          </div>

          <div className={styles.btnsContainerMob}>
            <button>סמן הכל</button>
            <button onClick={printRequestsMobile}>הדפס</button>
          </div>
        </div>

        {/* PART IMAGE MODAL */}
        {imagePopup && (
          <div className={styles.popupContainer}>
            <div
              style={{
                height: "100vh",
                width: "100%",
                position: "fixed",
                left: "0px",
                top: "0px",
                opacity: 0.45,
                background: "#000000 0% 0% no-repeat padding-box",
              }}
              onClick={() => setImagePopup(null)}
            ></div>
            <div className={styles.popup}>
              <span onClick={() => setImagePopup(null)}>&times;</span>
              <img src={imagePopup} alt="part" />
            </div>
          </div>
        )}

        {/*  LICENSE NUMBER DETAILS MODAL */}
        {licensePopup && (
          <LicenseModal
            setIsOpen={setLicensePopup}
            licenseData={licensePopup}
          />
        )}

        {isConfirmModal && (
          <ConfirmModal
            setIsOpen={setIsConfirmModal}
            heading="האם להעביר לארכיון?"
            text="פעולה זו תעביר את הבקשה לארכיון"
            note="תמיד ניתן להחזיר בקשה מהארכיון"
            yesBtn="העבר לארכיון"
            noBtn="יציאה"
            onYes={() => moveToArchive(isConfirmModal)}
          />
        )}
      </div>
    </Layout>
  );
};

export default SubscriberRequests;

const LicenseRowDesktop = ({ i, setLicensePopup }) => {
  const { searchForLicense, licenseLoading } = useLicenseSearch();

  const [licenseDataToSend, setLicenseDataToSend] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await searchForLicense(i.carLicenseNumber);
        if (response.success) {
          setLicenseDataToSend(response.data);
        } else {
          setLicenseDataToSend({});
          toast.error(response.message);
        }
      } catch (e) {
        console.log("Error while searching license. Reason:", e);
      }
    };

    fetchData();
  }, []);

  return (
    <td
      className={styles.license}
      style={{
        color: i.carLicenseNumber != "000000" ? "blue" : "black",
        cursor: i.carLicenseNumber != "000000" ? "pointer" : "default",
      }}
      onClick={() => {
        if (i.carLicenseNumber && i.carLicenseNumber != "000000") {
          setLicensePopup(licenseDataToSend);
        }
      }}
    >
      {i.carLicenseNumber && i.carLicenseNumber != "000000"
        ? i.carLicenseNumber
        : "לא הוזן מספר רכב"}
    </td>
  );
};
