import { fieldNames } from "../hooks/useLicenseSearch";
import styles from "../styles/_components/licenseModal.module.scss";
import { format } from "date-fns";

const style = {
  height: "100vh",
  width: "100%",
  position: "fixed",
  left: "0px",
  top: "0px",
  opacity: 0.45,
  background: "#000000 0% 0% no-repeat padding-box",
};

const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return format(date, "dd/MM/yyyy");
};

const Backdrop = ({ setIsOpen }) => (
  <div style={style} onClick={() => setIsOpen(null)}></div>
);

// Utility function to remove leading zeros
const removeLeadingZeros = (value) => {
  if (typeof value === "string") {
    return value.replace(/^0+/, "");
  }
  return value;
};

// Add helper function for gearbox type
const getGearboxType = (value) => {
  const gearTypes = {
    0: "ידני",
    1: "אוטומט",
    2: "רובוטי",
    3: "טיפטרוניק",
  };
  return gearTypes[value] || value;
};

const LicenseModal = ({ setIsOpen, licenseData }) => {
  const handlePrint = () => {
    const printableContent = document.getElementById("printableContent");

    if (printableContent) {
      const printWindow = window.open("", "", "width=600,height=600");
      printWindow.document.open();
      printWindow.document.write("<html><head><title>Print</title>");
      printWindow.document.write("<style>");

      // Add inline styles within the style tag
      printWindow.document.write(`
        body { font-size: 12px; direction: rtl; }
        .printable-content { margin: 20px; padding: 10px; border: 1px solid #000; }
        h1 { font-size: 20px; }
        ul { list-style-type: none; padding: 0; }
        li { margin: 2px 0; }
        li span { font-weight: bold; }

        div { width: 100%; height: 100%; display: flex; flex-direction: column; }
        ul { display: flex; flex-wrap: wrap; justify-content: space-between; }
        li { margin-bottom: -10px; list-style: none; width: 49%; display: flex; align-items: center; gap: 5px; }
        p:nth-child(1) { font-weight: bold; }
      `);

      printWindow.document.write("</style>");
      printWindow.document.write("</head><body>");

      printWindow.document.write(printableContent.innerHTML);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
      // printWindow.close();
    } else {
      console.warn("Printable content not found.");
    }
  };

  console.log(licenseData, "============= LICENSE MODAL DATA ===============");

  return (
    <div className={styles.mainContainer}>
      <Backdrop setIsOpen={setIsOpen} />
      <div className={styles.container}>
        <span className={styles.close} onClick={() => setIsOpen(null)}>
          x
        </span>
        <button className={styles.printBtn} onClick={handlePrint}>
          הדפסת נתונים
        </button>
        <div className={styles.content} id="printableContent">
          <div className={styles.header}>
            <h2>נתוני הרכב</h2>
            <div className={styles.mainDetails}>
              {licenseData.manufacturer && (
                <div className={styles.mainDetail}>
                  <span className={styles.label}>יצרן</span>
                  <span className={styles.value}>
                    {licenseData.manufacturer}
                  </span>
                </div>
              )}
              {licenseData.degem_nm && (
                <div className={styles.mainDetail}>
                  <span className={styles.label}>דגם</span>
                  <span className={styles.value}>{licenseData.degem_nm}</span>
                </div>
              )}
              {licenseData.yop && (
                <div className={styles.mainDetail}>
                  <span className={styles.label}>שנת יצור</span>
                  <span className={styles.value}>
                    {removeLeadingZeros(licenseData.yop)}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className={styles.sections}>
            <div className={styles.section}>
              <h3>פרטי רכב בסיסיים</h3>
              <ul>
                {[
                  "mispar_rechev",
                  "tozeret_nm",
                  "kinuy_mishari",
                  "shnat_yitzur",
                  "tzeva_rechev",
                  "misgeret",
                  "baalut",
                  "merkav",
                ].map(
                  (field) =>
                    licenseData[field] && (
                      <li key={field}>
                        <p>{fieldNames[field]}:</p>
                        <p>{removeLeadingZeros(licenseData[field])}</p>
                      </li>
                    )
                )}
              </ul>
            </div>

            <div className={styles.section}>
              <h3>מנוע ומערכות</h3>
              <ul>
                {[
                  "sug_delek_nm",
                  "nefah_manoa",
                  "degem_manoa",
                  "hanaa_nm",
                  "delek_nm",
                  "technologiat_hanaa_nm",
                  "koah_sus",
                  "mazgan_ind",
                  "abs_ind",
                  "automatic_ind",
                ].map(
                  (field) =>
                    licenseData[field] && (
                      <li key={field}>
                        <p>{fieldNames[field]}:</p>
                        <p>
                          {field === "automatic_ind"
                            ? getGearboxType(licenseData[field])
                            : removeLeadingZeros(licenseData[field])}
                        </p>
                      </li>
                    )
                )}
              </ul>
            </div>

            <div className={styles.section}>
              <h3>מפרט טכני</h3>
              <ul>
                {[
                  "mispar_dlatot",
                  "mispar_moshavim",
                  "mishkal_kolel",
                  "ramat_gimur",
                  "ramat_eivzur_betihuty",
                  "kvutzat_zihum",
                  "zmig_kidmi",
                  "zmig_ahori",
                  "kosher_grira_bli_blamim",
                  "kosher_grira_im_blamim",
                ].map(
                  (field) =>
                    licenseData[field] && (
                      <li key={field}>
                        <p>{fieldNames[field]}:</p>
                        <p>{removeLeadingZeros(licenseData[field])}</p>
                      </li>
                    )
                )}
              </ul>
            </div>

            <div className={styles.section}>
              <h3>מידע נוסף ותאריכים</h3>
              <ul>
                {[
                  "tokef_dt",
                  "mivchan_acharon_dt",
                  "moed_aliya_lakvish",
                  "horaat_rishum",
                  "kvuzat_agra_cd",
                  "degem_cd",
                  "tozeret_cd",
                  "sug_degem",
                ].map(
                  (field) =>
                    licenseData[field] && (
                      <li key={field}>
                        <p>{fieldNames[field]}:</p>
                        <p>{licenseData[field]}</p>
                      </li>
                    )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseModal;
