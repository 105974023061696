import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import SUBSCRIBER from "../api/services/SUBSCRIBER";
import { isTokenExpired } from "../utils/isTokenExpired";
import { IMAGE_BASE_URL } from "../config";
import Layout from "../layouts/Layout";
import Loader from "../components/Loader";
import { paths } from "../routes/paths";
import { toast } from "react-toastify";
import styles from "../styles/_pages/archive.module.scss";

const SubscriberArchive = () => {
  const navigate = useNavigate();
  const componentRefs = useRef({}); // to keep track of each row's ref

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [requests, setRequests] = useState();

  const [requestsCopy, setRequestsCopy] = useState();

  const [request, setRequest] = useState([]);

  const [removed, setRemoved] = useState(null);

  let token = localStorage.getItem("authToken");

  let userType = localStorage.getItem("user_type");

  // REDIRECT TO LOGIN IF NO TOKEN
  useEffect(() => {
    if (!token) {
      navigate(paths.login);
    }
  }, [token]);

  // IF THE USER IS NOT SUPPLIER THEN NAVIGATE HIM FROM THIS SCREEN
  useEffect(() => {
    if (JSON.parse(userType) !== "SUBSCRIBER") {
      navigate(paths.main);
    }
  }, [userType]);

  // FETCH REQUESTS
  useEffect(() => {
    setRemoved(null);
    const fetchRequests = async () => {
      try {
        const res = await SUBSCRIBER.subscriber_requests();
        if (res.result.length > 0) {
          setRequests(res.result.filter((x) => x.isArchivedBySubscriber));
          setRequestsCopy(res.result.filter((x) => x.isArchivedBySubscriber));
        } else {
          setRequests([]);
          setRequestsCopy([]);
        }
      } catch (error) {
        //console.log(error);
        setRequests([]);
        setRequestsCopy([]);
        isTokenExpired(error.response.data.status, navigate);
      }
    };

    fetchRequests();
  }, [removed]);

  // REMOVE FROM ARCHIVE
  const removeFromArchive = async (id) => {
    const payload = { id, status: false };

    try {
      const res = await SUBSCRIBER.move_to_archive(payload);
      toast.success(res.data.message);
      setRemoved(true);
    } catch (error) {
      //console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* CARD */}

          <h2>ארכיון בקשות</h2>

          {!requestsCopy && <Loader />}
          {requestsCopy && (
            <div className={styles.card}>
              {/* TABLE ----------- */}
              <div style={{ overflowX: "auto", width: "100%" }}>
                <table className={styles.mainTable}>
                  <thead>
                    <tr>
                      {/* <th style={{ width: "70px" }}></th> */}
                      {/* Client Name */}

                      {/* Manufacturer */}
                      <th>יצרן</th>
                      {/* Car license number */}
                      <th>מס' רכב</th>
                      {/* Part name */}
                      <th>חלק</th>
                      {/* search date  */}
                      <th>תאריך בקשה</th>
                      {/* car model */}
                      <th>דגם</th>
                      {/* Year of car production */}
                      <th>שנת יצור</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {requestsCopy.map((i, idx) => {
                      // Check if the ref for this ID already exists, if not, create one
                      if (!componentRefs.current[i._id]) {
                        componentRefs.current[i._id] = React.createRef();
                      }
                      return (
                        <React.Fragment key={i._id}>
                          <tr
                            ref={componentRefs.current[i._id]}
                            onClick={() => {
                              if (request.includes(i._id)) {
                                setRequest(request.filter((x) => i._id !== x));
                              } else if (
                                i.supplierReplies &&
                                i.supplierReplies.length > 0
                              ) {
                                setRequest((prev) => [...prev, i._id]);
                              }
                            }}
                            style={{
                              cursor:
                                i.supplierReplies &&
                                i.supplierReplies.length > 0
                                  ? "pointer"
                                  : "default",
                            }}
                          >
                            {/* <td>
                          <input
                            type="checkbox"
                            // checked={selectedRequests.find((x) => x._id === i._id)}
                            // onClick={() => handleCheckBox(i._id)}
                          />
                        </td> */}

                            {/* manufacturer */}
                            <td>{i.manufacturer}</td>
                            {/* car license number */}
                            <td>
                              {i.carLicenseNumber ? i.carLicenseNumber : "-"}
                            </td>
                            {/* part name */}
                            <td className={styles.part}>
                              {!i.customPartName ? (
                                <div className={styles.part}>
                                  {i.images && i.images.length > 0
                                    ? i.images.map((i) => (
                                        <img
                                          src={`${IMAGE_BASE_URL}${i}`}
                                          alt="part"
                                          // onClick={() =>
                                          //   setImagePopup(`${IMAGE_BASE_URL}${i}`)
                                          // }
                                        />
                                      ))
                                    : null}
                                  {!i.isVehicle && i.part && i.part.name
                                    ? i.part.name
                                    : "רכב שלם"}
                                </div>
                              ) : (
                                <div className={styles.part}>
                                  {i.customPartName}
                                </div>
                              )}
                            </td>
                            {/* search date */}
                            <td>
                              {i.createdAt
                                ? format(new Date(i.createdAt), "dd.MM.yyyy")
                                : "-"}
                            </td>
                            {/* car model */}
                            <td>{i.model ? i.model : "-"}</td>
                            {/* yop */}
                            <td>{i.yop ? i.yop : "-"}</td>
                            <td style={{ cursor: "pointer" }}>
                              <button
                                style={{
                                  width: "auto",
                                  height: "25px",
                                  padding: "0 10px",
                                  background:
                                    "#3762ec 0% 0% no-repeat padding-box",
                                  borderRadius: "5px",
                                  border: "none",
                                  fontFamily: '"Open Sans", sans-serif',
                                  fontSize: "13px",
                                  lineHeight: "17px",
                                  letterSpacing: "0px",
                                  color: "#ffffff",
                                  cursor: "pointer",
                                }}
                                onClick={() => removeFromArchive(i._id)}
                              >
                                העבר חזרה לבקשות
                              </button>
                            </td>
                          </tr>
                          {request.includes(i._id) && (
                            <>
                              <tr
                                id={`dropdown-${i._id}`}
                                className={`${styles.collapsedRow} dropdown-content`}
                              >
                                <td colspan={11}>
                                  <table className={styles.collapsedTable}>
                                    <thead>
                                      {/* <th style={{ width: "70px" }}></th> */}
                                      <th>תאריך תגובה</th>
                                      <th>שם הספק</th>
                                      <th>מחיר</th>
                                      <th>הערה מספק</th>
                                      <th>כתובת הספק</th>
                                      <th>יצירת קשר</th>
                                    </thead>
                                    <tbody>
                                      {i.supplierReplies &&
                                        i.supplierReplies.map((j) => {
                                          return (
                                            <tr>
                                              <td
                                                style={{
                                                  border:
                                                    "1px solid  #00000073",
                                                }}
                                              >
                                                <p>
                                                  {format(
                                                    new Date(j.createdAt),
                                                    "dd.MM.yyyy - HH:mm:ss"
                                                  )}
                                                </p>
                                              </td>
                                              <td
                                                style={{
                                                  border:
                                                    "1px solid  #00000073",
                                                }}
                                              >
                                                {j.supplier[0].name}
                                              </td>
                                              <td
                                                style={{
                                                  border:
                                                    "1px solid  #00000073",
                                                }}
                                              >
                                                {j.price &&
                                                  new Intl.NumberFormat(
                                                    "he-IL",
                                                    {
                                                      style: "currency",
                                                      currency: "ILS",
                                                      minimumFractionDigits: 0,
                                                      maximumFractionDigits: 0,
                                                    }
                                                  ).format(j.price)}
                                                <p>
                                                  {j.price === ""
                                                    ? "לא קיים במלאי"
                                                    : ""}
                                                </p>
                                              </td>

                                              <td
                                                style={{
                                                  border:
                                                    "1px solid  #00000073",
                                                }}
                                              >
                                                {j.comment && j.comment}
                                                {j.status === "DELETE" && (
                                                  <span
                                                    style={{
                                                      color: "#cc1919",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {" "}
                                                    לא קיים במלאי
                                                  </span>
                                                )}
                                              </td>
                                              <td
                                                style={{
                                                  border:
                                                    "1px solid  #00000073",
                                                }}
                                              >
                                                {j.supplier[0].address},{" "}
                                                {j.supplier[0].city}
                                              </td>
                                              <td
                                                style={{
                                                  border:
                                                    "1px solid  #00000073",
                                                }}
                                              >
                                                <div
                                                  className={styles.contact}
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "flex-start",
                                                    gap: "5px",
                                                  }}
                                                >
                                                  <a
                                                    href={`tel:+972${j.supplier[0].phone}`}
                                                  >
                                                    {j.supplier[0].phone}
                                                  </a>
                                                  <a
                                                    href={`tel:+972${j.supplier[0].additionalPhone}`}
                                                  >
                                                    {
                                                      j.supplier[0]
                                                        .additionalPhone
                                                    }
                                                  </a>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
                {requestsCopy && requestsCopy.length === 0 && (
                  <>
                    <div
                      style={{
                        margin: "100px",
                        textAlign: "center",
                        backgroundColor: "white",
                      }}
                    >
                      <h2>לא נמצאו רשומות</h2>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          {/* MOBILE */}
          <div className={styles.cardsWrapper}>
            {/* CARDS */}
            {requestsCopy && (
              <div className={styles.cards}>
                {requestsCopy.map((i, idx) => (
                  <>
                    <div className={`${styles.reqCard}`}>
                      <div className={styles.item}>
                        <h3>יצרן</h3>
                        <p>{i.manufacturer}</p>
                      </div>
                      <div className={styles.item}>
                        <h3>מס' רכב</h3>
                        <p>{i.carLicenseNumber ? i.carLicenseNumber : "-"}</p>
                      </div>
                      <div className={styles.item}>
                        <h3>חלק</h3>
                        <p>
                          {!i.customPartName
                            ? !i.isVehicle && i.part && i.part.name
                              ? i.part.name
                              : "רכב שלם"
                            : i.customPartName}
                        </p>
                      </div>
                      <div className={styles.item}>
                        <h3>תאריך בקשה</h3>
                        <p>{format(new Date(i.createdAt), "dd.MM.yyyy")}</p>
                      </div>
                      <div className={styles.item}>
                        <h3>דגם</h3>
                        <p>{i.model ? i.model : "-"}</p>
                      </div>
                      <div className={styles.item}>
                        <h3>שנת יצור</h3>
                        <p>{i.yop ? i.yop : "-"}</p>
                      </div>
                      <div className={styles.item}>
                        <img
                          src="/assets/icons/trash.svg"
                          alt="delete"
                          onClick={() => removeFromArchive(i._id)}
                        />
                      </div>
                    </div>
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SubscriberArchive;
