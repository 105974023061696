import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import MANUFACTURERS from "../../api/services/MANUFACTURERS.js";
import PARTS from "../../api/services/PARTS.js";
import Spinner from "../../components/Spinner.jsx";
import { useForm } from "react-hook-form";
import { fieldNames, useLicenseSearch } from "../../hooks/useLicenseSearch.js";
import styles from "../../styles/_features/createRequest/details.module.scss";

const Details = ({
  manufacturers,
  setManufacturers,
  models,
  setModels,
  vehicle,
  setVehicle,
  manufacturer,
  setManufacturer,
  model,
  setModel,
  subModel,
  setSubModel,
  yop,
  setYop,
  gearType,
  setGearType,
  engineType,
  setEngineType,
  hp,
  setHp,
  vehicleNo,
  setVehicleNo,
  carLicenceNo,
  setCarLicenceNo,
  setDuplicateArr,
  selectedParts,
  setSelectedParts,
  setCollapseSections,
  licenceNum,
  setLicenceNum,
  disableAll,
  setDisableAll,
  licenseData,
  setLicenseData,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const [allParts, setAllParts] = useState();

  const [allPartsCopy, setAllPartsCopy] = useState();

  const [searchVal, setSearchVal] = useState("");

  const [selectedVal, setSelectedVal] = useState("");

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [subModelError, setSubModelError] = useState(null);
  const [yopError, setYopError] = useState(null);
  const [gearTypeError, setGearTypeError] = useState(null);
  const [engineTypeError, setEngineTypeError] = useState(null);
  const [hpError, setHpError] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);

  // const executeScroll = () => window.scrollTo(0, 680);

  let vehicleDetailFields = {
    mispar_rechev: "מספר רכב",
    tozeret_nm: "יצרן",
    kinuy_mishari: "דגם",

    degem_cd: "קוד דגם",
    nefah_manoa: "נפח מנוע",
    nefach_manoa: "נפח מנוע",
    sug_degem: "סוג דגם",
    tozeret_cd: "קוד יצרן",

    degem_nm: "שם דגם",
    ramat_gimur: "רמת גימור",
    ramat_eivzur_betihuty: "רמת אבזור בטיחותי",
    kvutzat_zihum: "קבוצת זיהום",
    shnat_yitzur: "שנת יצור",
    degem_manoa: "דגם מנוע",
    mivchan_acharon_dt: "טסט עד",
    mispar_dlatot: "מספר דלתות",
    tokef_dt: "תוקף רישיון רכב",
    baalut: "בעלות",
    misgeret: "מספר שלדה",
    tzeva_cd: "קוד צבע",
    tzeva_rechev: "צבע רכב",
    zmig_kidmi: "צמיג קדמי",
    zmig_ahori: "צמיג אחורי",
    sug_delek_nm: "סוג דלק",
    horaat_rishum: "הוראת רישום",
    moed_aliya_lakvish: "מועד עלייה לכביש",
    hanaa_nm: "הנעה",
    delek_nm: "דלק",
    mazgan_ind: "מזגן",
    technologiat_hanaa_nm: "טכנולוגית הנעה",
    kosher_grira_bli_blamim: "כושר גלילה בלי בלמים",
    kvuzat_agra_cd: "קבוצת אגרה",
    merkav: "מרכב",
    automatic_ind: "תיבת הילוכים",
    mispar_moshavim: "מספר מושבים",
    mishkal_kolel: "משקל",
    koah_sus: "כוס סוס",
    abs_ind: "ABS",
    kosher_grira_im_blamim: "כושר גרירה עם בלמים",
  };

  // call manufacturers API
  const getManufacturers = async () => {
    try {
      const res = await MANUFACTURERS.get(vehicle);

      if (res.result.length === 0) {
        setManufacturers([]);
      } else {
        let sortedManufacturers = res.result.manufacturers.sort((a, b) => {
          // Compare manufacturers' names alphabetically
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        if (manufacturer) {
          // Add the custom manufacturer to the sorted list
          sortedManufacturers = [
            ...sortedManufacturers,
            { name: manufacturer },
          ];
        }

        setManufacturers(sortedManufacturers);

        let updatedModels = res.result.manufacturers[0].models;
        if (model) {
          // Add the custom model to the models list
          updatedModels = [...updatedModels, model];
        }

        setModels(updatedModels);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  // fetch manufacturers list
  useEffect(() => {
    getManufacturers();
  }, [vehicle]);

  // fetch all parts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await PARTS.get_all();
        //console.log(res, "PARTSSSSSSSSSSSSSSSS");

        if (res.result.length === 0) {
          setAllParts([]);
          setAllPartsCopy([]);
        } else {
          let filtered = res.result.filter(
            (x) => x.categoryId.vehicle === vehicle
          );
          setAllParts(filtered);
          setAllPartsCopy(filtered);
        }
      } catch (error) {
        //console.log(error);
        toast.error(error.response.data.message);
      }
    };

    fetchData();
  }, [vehicle]);

  // filter all parts
  // useEffect(() => {
  //   if (allParts && allParts.length > 0) {
  //     if (searchVal.trim()) {
  //       let filtered = allParts.filter((i) => i.name.includes(searchVal));
  //       setAllPartsCopy([
  //         ...filtered,
  //         { _id: searchVal, price: 0, categoryId: searchVal, name: searchVal },
  //       ]);
  //     } else {
  //       setAllPartsCopy(allParts);
  //     }
  //   }
  // }, [searchVal, allParts]);

  // filter all parts
  useEffect(() => {
    if (allParts && allParts.length > 0) {
      if (searchVal.trim()) {
        let filtered = allParts.filter((i) => i.name.includes(searchVal));
        //console.log(filtered, "filtered");

        // if the searched part exists in the existing parts list
        if (filtered.length > 0) {
          setAllPartsCopy([...filtered]);
        }
        // if the searched part doesn't exists in the existing parts list
        else {
          setAllPartsCopy([
            {
              _id: searchVal,
              price: 0,
              categoryId: searchVal,
              name: searchVal,
              custom: true,
            },
          ]);
        }
      } else {
        setAllPartsCopy(allParts);
      }
    }
  }, [searchVal, allParts]);

  // handle search
  const handleSearch = () => {
    if (selectedVal) {
      setSelectedParts(selectedParts.filter((i) => i._id !== selectedVal._id));
      setSelectedParts((prev) => [...prev, selectedVal]);
      setSearchVal("");
      setSelectedVal("");
    } else if (searchVal.trim()) {
      const prt = {
        name: searchVal,
        custom: true,
      };
      setSelectedParts((prev) => [...prev, prt]);
    }
  };

  // ===========================================================
  // =========== EXAMPLE LICENSE NO: 75348102, 6618355 =========
  // ===========================================================

  const { searchForLicense, loading } = useLicenseSearch();

  const handleLicenseSearch = React.useCallback(
    async (text) => {
      try {
        const response = await searchForLicense(text);
        if (response.success) {
          // SET ALL LICENSE DATA
          setLicenseData(response.data);

          // CHECK IF MANUFACTURER EXISTS IN MANUFACTURERS ARRAY
          let doesExist = (manufacturers || []).find(
            (i) => i.name === response.localData.manufacturer
          );

          // IF IT EXISTS
          if (doesExist) {
            setManufacturer(response.localData.manufacturer);
            setModels(() => {
              if ((doesExist.models || []).includes(response.localData.model)) {
                return doesExist.models;
              } else {
                return [...(doesExist.models || []), response.localData.model];
              }
            });
            setModel(response.localData.model);
          }

          // IF IT DOESN'T EXISTS
          else {
            setManufacturers((prev) => [
              ...(prev || []),
              {
                id: response.localData.manufacturer,
                name: response.localData.manufacturer,
              },
            ]);
            setManufacturer(response.localData.manufacturer);
            setModels([response.localData.model]);
            setModel(response.localData.model);
          }

          // SET OTHER FIELDS
          setVehicle(response.localData.vehicle);

          if (response.localData.yop) {
            setYop(response.localData.yop);
            setLicenseData((prev) => {
              return {
                ...prev,
                shnat_yitzur: response.localData.yop,
              };
            });
          } else {
            setYopError(true);
          }
          if (response.localData.hp) {
            setHp(response.localData.hp);
          } else {
            setHpError(true);
          }
          console.log(response.localData, 'response.localData')
          if (response.localData.gearType) {
            setGearType(response.localData.gearType);
          } else {
            setGearTypeError(true);
          }
          if (response.localData.engineType) {
            setEngineType(response.localData.engineType);
          } else {
            setEngineTypeError(true);
          }
        } else {
          setLicenseData({});
          toast.error(response.message);
        }
        console.log("FINAL RESP", response);
      } catch (e) {
        console.log("Error while searching license. Reason:", e);
      }
    },
    [manufacturers, searchForLicense]
  );

  useEffect(() => {
    const validateForm = () => {
      if (!hp || !gearType || !yop || !engineType) {
        setIsFormValid(false);
      } else {
        setIsFormValid(true);
      }
    };

    validateForm();
  }, [hp, gearType, yop, engineType]);
  return (
    <>
      {loading && (
        <div className={styles.outer}>
          <div className={styles.middle}>
            <div className={styles.inner}></div>
          </div>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={`${styles.vehicles} ${styles.vehiclesDesktop}`}>
            <button
              className={`${
                vehicle === "CAR" && !disableAll ? styles.active : ""
              } ${styles.tab}
              ${disableAll ? styles.disable : ""}
              `}
              onClick={() => {
                if (vehicle !== "CAR") {
                  setDuplicateArr([]);
                }
                setVehicle("CAR");
              }}
              disabled={disableAll}
            >
              <img src="/assets/icons/car.svg" alt="car" />
              רכב
            </button>
            <button
              className={`${
                vehicle === "TRUCK" && !disableAll ? styles.active : ""
              } ${styles.tab} ${disableAll ? styles.disable : ""}`}
              onClick={() => {
                if (vehicle !== "TRUCK") {
                  setDuplicateArr([]);
                }
                setVehicle("TRUCK");
              }}
              disabled={disableAll}
            >
              <img src="/assets/icons/truck.svg" alt="truck" />
              משאית
            </button>
            <button
              className={`${
                vehicle === "BIKE" && !disableAll ? styles.active : ""
              } ${styles.tab} ${disableAll ? styles.disable : ""}`}
              onClick={() => {
                if (vehicle !== "BIKE") {
                  setDuplicateArr([]);
                }
                setVehicle("BIKE");
              }}
              disabled={disableAll}
            >
              <img src="/assets/icons/bike.svg" alt="bike" />
              אופנוע
            </button>
          </div>

          {/* license api details button */}
          {!!Object.keys(licenseData).length && (
            <button
              className={`${styles.infoBtn} ${styles.infoBtnDesktop}`}
              // disabled={!disableAll}
              disabled={!Object.keys(licenseData).length}
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              פרטי כלי הרכב
            </button>
          )}

          {/* license api details card */}
          {dropdownOpen && (
            <div className={styles.dropCard}>
              <span onClick={() => setDropdownOpen(!dropdownOpen)}>X</span>
              <ul>
                {!!Object.keys(licenseData).length &&
                  Object.keys(fieldNames).map(
                    (i, idx) =>
                      licenseData[i] && (
                        <li key={idx}>
                          <p>{fieldNames[i]}:</p>
                          <p>{licenseData[i]}</p>
                        </li>
                      )
                  )}
              </ul>
            </div>
          )}
        </div>

        <div className={styles.card}>
          <img
            src="/assets/images/curved.svg"
            alt="object"
            className={styles.curved}
          />

          {/* license fields */}
          <form
            className={styles.licenseContainer}
            onSubmit={(e) => {
              e.preventDefault();
              if (licenceNum.length >= 5) {
                handleLicenseSearch(licenceNum);
                // vehicle === "CAR"
                //   ? handleCarApi()
                //   : vehicle === "BIKE"
                //   ? handleBikeApi()
                //   : handleTruckApi();
              }
            }}
          >
            <div className={styles.licenseNum}>
              <div className={styles.wrapper}>
                <div className={styles.left}>
                  <input
                    type="text"
                    placeholder="מספר הרכב שלך"
                    value={licenceNum}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/\D/g, "");
                      console.log(numericValue); // Log the value
                      setLicenceNum(numericValue);
                    }}
                  />
                </div>
                <div className={styles.right}>
                  <img src="/assets/icons/flag.svg" alt="flag" />
                  IL
                </div>
              </div>
            </div>
            {licenceNum.length >= 5 && (
              <button type="submit">{loading ? <Spinner /> : "חיפוש"}</button>
            )}
          </form>

          {/* license api details button for mobile */}
          {!!Object.keys(licenseData).length && (
            <button
              className={`${styles.infoBtn} ${styles.infoBtnMobile}`}
              // disabled={!disableAll}
              disabled={!Object.keys(licenseData).length}
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              פרטי כלי הרכב
            </button>
          )}

          {/* vehicles component for mobile */}
          <div className={`${styles.vehicles} ${styles.vehiclesMobile}`}>
            <button
              className={`${
                vehicle === "CAR" && !disableAll ? styles.active : ""
              } ${styles.tab}
              ${disableAll ? styles.disable : ""}
              `}
              onClick={() => {
                if (vehicle !== "CAR") {
                  setDuplicateArr([]);
                }
                setVehicle("CAR");
              }}
              disabled={disableAll}
            >
              <img src="/assets/icons/car.svg" alt="car" />
              רכב
            </button>
            <button
              className={`${
                vehicle === "TRUCK" && !disableAll ? styles.active : ""
              } ${styles.tab} ${disableAll ? styles.disable : ""}`}
              onClick={() => {
                if (vehicle !== "TRUCK") {
                  setDuplicateArr([]);
                }
                setVehicle("TRUCK");
              }}
              disabled={disableAll}
            >
              <img src="/assets/icons/truck.svg" alt="truck" />
              משאית
            </button>
            <button
              className={`${
                vehicle === "BIKE" && !disableAll ? styles.active : ""
              } ${styles.tab} ${disableAll ? styles.disable : ""}`}
              onClick={() => {
                if (vehicle !== "BIKE") {
                  setDuplicateArr([]);
                }
                setVehicle("BIKE");
              }}
              disabled={disableAll}
            >
              <img src="/assets/icons/bike.svg" alt="bike" />
              אופנוע
            </button>
          </div>

          {/* other input fields */}
          <div className={styles.row}>
            {/* MANUFACTURER */}
            <select
              name="manufacturer"
              value={manufacturer}
              // disabled={disableAll}
              style={{
                border:
                  manufacturer === "לא צוין"
                    ? "2px solid red"
                    : "1px solid #95a5b2",
              }}
              onChange={(e) => {
                setManufacturer(e.target.value);
                setModels(() => {
                  let item = manufacturers.find(
                    (i) => i.name === e.target.value
                  );
                  if (item) {
                    return item.models;
                  } else {
                    return null;
                  }
                });
              }}
            >
              <option value="" selected disabled>
                בחר יצרן
              </option>
              {manufacturers &&
                manufacturers.map((i) => (
                  <option key={i.id} value={i.name}>
                    {i.name}
                  </option>
                ))}
            </select>

            {/* MODEL */}
            {models && (
              <select
                name="model"
                value={model}
                // disabled={disableAll}
                onChange={(e) => setModel(e.target.value)}
              >
                <option value="" selected disabled>
                  בחר דגם
                </option>
                {models.map((i, idx) => (
                  <option value={i} key={idx}>
                    {i}
                  </option>
                ))}
              </select>
            )}

            {/* SUB MODEL */}
            <input
              type="text"
              value={subModel}
              onChange={(e) => {
                setSubModelError(null);
                setSubModel(e.target.value);
              }}
              placeholder="תת דגם"
              style={{
                border: subModelError ? "2px solid red" : "1px solid #95a5b2",
              }}
            />

            {/* YEAR OF PROD */}
            <input
              type="text"
              value={yop}
              onChange={(e) => {
                setYopError(null);
                setYop(e.target.value);
              }}
              placeholder="שנת ייצור"
              style={{
                border: !yop ? "2px solid red" : "1px solid #95a5b2",
              }}
            />

            {/* GEAR TYPE */}
            {vehicle !== "BIKE" && (
              <select
                name="gearType"
                value={gearType}
                onChange={(e) => {
                  setGearTypeError(null);
                  setGearType(e.target.value);
                }}
                style={{
                  border: !gearType ? "2px solid red" : "1px solid #95a5b2",
                }}
              >
                <option value="" selected disabled>
                  תיבת הילוכים
                </option>
                <option value="AUTOMATIC">אוטומטי</option>
                <option value="MANUAL">ידני</option>
                <option value="TIPTONIC">טיפטרוניק</option>
                <option value="ROBOTIC">רובוטי</option>
              </select>
            )}

            {/* <input
            type="text"
            value={gearType}
            onChange={(e) => setGearType(e.target.value)}
            placeholder="גיר"
          /> */}

            {/* ENGINE TYPE */}
            <select
              name="engineType"
              value={engineType}
              onChange={(e) => {
                setEngineTypeError(null);
                setEngineType(e.target.value);
              }}
              style={{
                border: engineTypeError ? "2px solid red" : "1px solid #95a5b2",
              }}
            >
              <option value="" selected disabled>
                סוג מנוע
              </option>
              <option value="GASOLINE">בנזין</option>
              <option value="TURBO GASOLINE">טורבו בנזין</option>
              <option value="DIESEL">דיזל</option>
              <option value="TURBO DIESEL">טורבו דיזל</option>
              <option value="HYBRID">היברידי</option>
              <option value="ELECTRICAL POWER">חשמלי</option>
              <option value="ELECTRICITY/GASOLINE">חשמלי / בנזין</option>
              <option value="GAPAM">גפ"מ</option>
            </select>

            {/* HORSE POWER */}
            <input
              type="text"
              value={hp}
              onChange={(e) => {
                setHpError(null);
                setHp(e.target.value);
              }}
              placeholder='סמ"ק'
              style={{
                border: !hp ? "2px solid red" : "1px solid #95a5b2",
              }}
            />

            {/* <input
              type="text"
              value={vehicleNo}
              onChange={(e) => setVehicleNo(e.target.value)}
              placeholder="מס' רכב"
              disabled={disableAll}
            />

            <input
              type="text"
              value={carLicenceNo}
              onChange={(e) => setCarLicenceNo(e.target.value)}
              placeholder="מס' רשיון"
              disabled={disableAll}
            /> */}
          </div>

          {/* search field  */}
          <div className={styles.search}>
            <input
              type="search"
              name="searchValue"
              style={{
                border:
                  !manufacturer ||
                  !model ||
                  model === "בחר דגם" ||
                  yop == "שנת ייצור" ||
                  !yop ||
                  !gearType ||
                  !hp ||
                  !gearType ||
                  gearType === "תיבת הילוכים" ||
                  !engineType ||
                  engineType === ""
                    ? "2px solid red"
                    : "1px solid #95a5b2",
              }}
              placeholder={
                !manufacturer ||
                !model ||
                model === "בחר דגם" ||
                yop == "שנת ייצור" ||
                !yop ||
                !gearType ||
                !hp ||
                !gearType ||
                gearType === "תיבת הילוכים" ||
                !engineType ||
                engineType === ""
                  ? "על מנת לחפש חלק נא מלאו את פרטי הרכב"
                  : "איזה חלק תרצו לחפש?"
              }
              value={searchVal}
              onChange={(e) => setSearchVal(e.target.value)}
              disabled={
                !manufacturer ||
                !model ||
                model === "בחר דגם" ||
                yop == "שנת ייצור" ||
                !yop ||
                !gearType ||
                !hp ||
                !gearType ||
                gearType === "תיבת הילוכים" ||
                !engineType ||
                engineType === ""
              }
              // onFocus={executeScroll}
            />

            <div className={styles.dropdown}>
              <ul>
                {allPartsCopy &&
                  allPartsCopy.map((i) => (
                    <li
                      key={i._id}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={selectedParts.some((j) => j._id === i._id)}
                        style={{
                          width: "18px",
                          boxShadow: "none",
                        }}
                        onChange={() => {
                          const obj = {
                            ...i,
                            custom: i.custom ? true : false,
                          };
                          setSelectedVal(i);
                          // setSearchVal(i.name);
                          setSelectedParts(
                            selectedParts.filter((j) => j._id !== i._id)
                          );
                          setSelectedParts((prev) => [...prev, obj]);
                          setSearchVal("");
                          setCollapseSections(true);
                        }}
                      />
                      {i.name}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
